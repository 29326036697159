function hasErrors(instance) {
  for(var prop in instance) {
    if (hasError(instance, prop)) {
      return true;
    }
  }

  return false;
}

function getAllErrors(instance) {
  let errors = [];

  for(var prop in instance) {
    if (hasError(instance, prop)) {
      errors.push({
        property: prop,
        messages: instance[prop]
      })
    }
  }

  return errors;
}

function hasError(instance, prop) {
  return instance.hasOwnProperty(prop) && Array.isArray(instance[prop]) && instance[prop].length;
}


export default class ValidationErrorCollection {
  constructor() {
    this.hasErrors = false;
    this.hasGlobalErrors = false;
    this.globalErrors = [];
  }
  
  addGlobalError(error) {
    this.globalErrors.push(error);
    this.hasErrors = true;
    this.hasGlobalErrors = true;
  }

  addError(prop, error) {
    if (!prop) {
        this.addGlobalError(error);
    }

    this[prop] = this[prop] || [];
    this[prop].push(error);
    this.hasErrors = true;
  }

  clearError(prop) {
    this[prop] = undefined;
    this.hasErrors = hasErrors(this);
  }

  getAllErrors() {
    return getAllErrors(this);
  }

  addGenericError() {
    this.addGlobalError('Sorry, something went wrong. Please try again.');
  }
}