import React from 'react';
import classList from 'react-classlist-helper';
import './CountrySelect.scss';

export default (props) => {
  const classMap = {
    "country-select country-select--blocks": true,
    "country-select--error": props.hasErrors,
  };
  
  return (
    <div className={classList(classMap)} >
       { props.legend &&
        <legend className="country-select__legend">
          Please tell us where you live:
        </legend>
      }
      <div className="country-select__container">
        <div className="country-select__item">
            <button type="submit" className="button" value="GB" name="Country" onClick={() => props.onChange('GB')}><span>Great Britain</span></button>
        </div>
        <div className="country-select__item">
            <button type="submit" className="button" value="NI" name="Country" onClick={() => props.onChange('NI')}><span>Northern Ireland</span></button>
        </div>
        <div className="country-select__item">
            <button type="submit"  className="button" value="ROI" name="Country" onClick={() => props.onChange('ROI')}><span>Republic of Ireland</span></button>
        </div>
      </div>
    </div>
  );
};