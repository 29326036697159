import React, { useState } from 'react';
import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';
import countryValidator from 'validators/countryValidator';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';
import ValidationMessage from 'components/Validation/ValidationMessage';
import analyticsService from 'services/analyticsService';
import ReCaptcha from 'components/Forms/ReCaptcha';
import Form, { createOnChangeHandler } from './Form';
import CountrySelectBlocks from 'components/Forms/CountrySelectBlocks';
import "./CountrySelectForm.scss";

export default (props) => {
  let [settings, setSettings ] = React.useContext(SettingsContext);
  let settingsContext = useSettingsContext();
  const [country, setCountry] = useState(!!settings.country);
  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  const createCommand = () => {
    return {
      country
    }
  }

  const onFormSuccess = () => {
    analyticsService.logEvent("forms", "Forms", {
      action: "CountrySelect",
      label: country
    });

    setSettings({...settings, country: settings.country});
  }

  const service = (command) => {
    props.onSubmit(command);
  }

  return (
    <Form 
      id="country"
      className="country-select"
      autoComplete="off"
      submitTitle="Enter"
      submitHidden
      onSuccess={onFormSuccess}
      command={{
        model: createCommand,
        service: service
      }}
      validation={{
        validator: countryValidator,
        onValidation: setValidation
      }}
    >
      <p className="country-select__intro" tabIndex="0">
        Please tell us where you live:
      </p>
      <div className="country-select__inputs">
        <CountrySelectBlocks id="country" label="country" onChange={createOnChangeHandler(validation, 'country', setCountry)} />
        <ValidationMessage errors={validation.errors.country} />
      </div>
      <ReCaptcha captchaSettings={settingsContext.captchaSettings} />
    </Form>
  )
}