import api from './api';

function login(data) {
    return api
        .post('account/login', data)
        .then(_updateAndReturnTokenAsPromise);
}

function logout() {
    return api
        .post('account/logout')
        .then(_updateAndReturnTokenAsPromise);
}

function register(data) {
    return api
        .post(`account/register`, data)
        .then(_updateAndReturnTokenAsPromise);
}

function forgottenPassword(data) {
    return api
        .post('account/password-reset/trigger', data);
}

function forgottenPasswordComplete(data) {
    return api
        .post('account/password-reset/complete', data);
}

function changePassword(data) {
    return api
        .post('account/update-password', data);
}

function getAccountDetails(data) {
    return api
        .post('account/get-account-details', data);
        
}
function _updateAndReturnTokenAsPromise(response) {
    api.updateXSRFToken(response.csrfToken);
    return new Promise(function(resolve, reject) {
        resolve({
            csrfToken: response.csrfToken,
            country: response.country,
            account: response.account
        });
    })
}

export default {
    login,
    logout,
    register,
    forgottenPassword,
    forgottenPasswordComplete,
    changePassword,
    getAccountDetails
};