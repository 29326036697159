import React from 'react';
import { Redirect } from 'react-router-dom';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import PrizeSelection from './PrizeSelection';
import useSettingsContext from 'contexts/useSettingsContext';

export default () => {
  const settings = useSettingsContext();
  let competitionEntryState = useCompetitionEntryContext();

  if (!settings.country) {
    return <Redirect to='/' />
  }

  const isIE = settings.country === 'ROI';

  if ((competitionEntryState.batchcode && competitionEntryState.barcode) || isIE) {
    return <PrizeSelection />
  } else {
    return <Redirect to='/' />
  }
}