import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';
import useCompetitionEntryState from 'contexts/useCompetitionEntryContext';
import timer from 'utils/timer';

import ValidationErrorCollection from 'validators/ValidationErrorCollection';

import CodeEntry from './CodeEntry';
import PrizeBlock from 'components/Prize/PrizeBlock';
import PrizeBlockDouble from 'components/Prize/PrizeBlockDouble';
import Step from 'components/Step/Step';
import Link from 'components/Link/Link';
import Button from 'components/Button/Button';
import SummaryTsandCs from 'components/SummaryTsandCs/SummaryTsandCs';

import Stage from 'components/Confetti/Stage';

import KeyVisual from './KeyVisual';

import How1 from 'assets/images/campaign/how-1.png';
import How1_2x from 'assets/images/campaign/how-1@2x.png';
import How2 from 'assets/images/campaign/how-2.png';
import How2_2x from 'assets/images/campaign/how-2@2x.png';
import How3 from 'assets/images/campaign/how-3.png';
import How3_2x from 'assets/images/campaign/how-3@2x.png';
import How4 from 'assets/images/campaign/how-4.png';
import How4_2x from 'assets/images/campaign/how-4@2x.png';
import How5 from 'assets/images/campaign/how-5.png';
import How5_2x from 'assets/images/campaign/how-5@2x.png';
import How6 from 'assets/images/campaign/how-6.png';
import How6_2x from 'assets/images/campaign/how-6@2x.png';

//PRIZES
import PrizeTier from 'assets/images/campaign/prizes/handshake.svg';
import PrizeTier2 from 'assets/images/campaign/prizes/away.svg';
import VouchersMob from 'assets/images/campaign/prizes/vouchers_Mob.png';
import VouchersMob2x from 'assets/images/campaign/prizes/vouchers_Mob@2x.png';
import Vouchers from 'assets/images/campaign/prizes/vouchers.png';
import Vouchers2x from 'assets/images/campaign/prizes/vouchers@2x.png';
import SignedShirtMob from 'assets/images/campaign/prizes/Signed_Shirt_Mob.png';
import SignedShirtMob2x from 'assets/images/campaign/prizes/Signed_Shirt_Mob@2x.png';
import SignedShirt from 'assets/images/campaign/prizes/Signed_Shirt.png';
import SignedShirt2x from 'assets/images/campaign/prizes/Signed_Shirt@2x.png';



import analyticsService from 'services/analyticsService';
import CountrySelect from './CountrySelect';

import './Home.scss';
import useEffectOnce from 'hooks/useEffectOnce';

import PlayerBadge from 'components/PlayerBadge/PlayerBadge';
import prizeList from 'utils/prizes';
import CountryCtaModal from './CountryCtaModal';

export default () => {
  const [settings, setSettings] = React.useContext(SettingsContext);
  let settingsState = useSettingsContext();
  let history = useHistory();
  let compState = settings.competitionStatus.openState;
  let compPhaseId = settings.competitionStatus.currentPhasePublicId;
  let competitionIsOpen = compState === "Open";
  let competitionIsNotStarted = compState === "NotStarted";
  let competitionIsMopUp = compPhaseId === "mop-up";
  let competitionIsClosed = compState === "Closed";
  const [showEntryModal, setShowEntryModal] = useState(false);
  const [showCountryModal, setShowCountryModal] = useState(!settings.country);
  const [showCountryCtaModal, setShowCountryCtaModal] = useState(false);
  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  let currency = (settings.country === "ROI" ? "€" : (settings.country === "GB" || settings.country === "NI") ? "£" : "£/€");
  let voucher = (settings.country === "ROI" ? "AllGifts.IE" : "Lifestyle");

  useEffectOnce(() => {
    var isDesk = window.matchMedia("only screen and (min-width: 1200px)").matches;
    var conffetiNodes = 80;
    if (isDesk && (!competitionIsNotStarted && competitionIsOpen)) {
      conffetiNodes = 200;
    }

    async function confetti() {
      Stage.init({
        nodeCount: conffetiNodes,
        typeGold: true
      });
    }

    if(competitionIsOpen && !competitionIsMopUp){
      timer.delay(500).then(() => confetti());
    }

    //timer.delay(5000).then(() => Stage.destroy());

    return () => Stage.destroy();

  });


  const handleCountrySelected = (data) => {
    sessionStorage && sessionStorage.setItem('cadburyfc.country', data.country);
    settingsState.setCountry(data.country);
    setShowCountryModal(false);
  }

  const handleEnterNow = async () => {
    if (settings.country === "GB" || settings.country === "NI") {
      analyticsService.logEvent("enterCompetition", "ProofOfPurchase", {
        action: "OpenProofOfPurchaseModal",
        label: "open modal"
      });

      setShowEntryModal(true);
    } else {
      analyticsService.logEvent("enterCompetition", "NoPurchaseNecessary", {
        action: "OpenPrizeSelection"
      });      
      history.push(!competitionIsMopUp ? "/prize-select":"/registration");
    }
  }

  const renderSteps = (steps) => {
    return steps.map((step, index) => {
      return (
        <Step key={step.number} {...step} />
      )
    })
  }

  const renderEntryCta = (title) => {
    if (!settings.country) return null;

    return <Button
      wide
      type="submit"
      title={title ? title : "Enter Now"}
      onClick={handleEnterNow}
    />
  }

  const renderIntroMessage = (compState) => {
    switch (compState) {
      case 'Open':
        return <>
        {
          !competitionIsMopUp ? (
            <div className="home__intro">
              <h2 className='type-h1'>Home & Away Wins is live!</h2>
              
              <div className="home__intro-body">
                <p>
                Are your boots laced up? As this summer’s promotion is on! WIN a meet here at HOME* with one of these football heroes (and 3 of your mates) & WIN a holiday AWAY to an epic destination worth {currency}5K.
                </p>
              </div>

              <div className='home__player-badges'>
                {
                  prizeList.map((p, index) => (
                    <PlayerBadge 
                      key={p.id}
                      tab={index + 1}
                      prize={p} 
                    />
                  ))
                }
              </div>

              <div className="home__intro-body">
                <div className='type-h3'>Pick your player from our epic line-up</div>
                <p>
                Plus we have 1000s more epic prizes, including signed shirts and personalised player videos.
                </p>
              </div>
              <div className="home__cta">
                {renderEntryCta("play now")}
              </div>
            </div>
          ) : (
            <div className="home__intro home__intro--slim">
              <h2 className='type-h3 type-h3--white'>This journey may be over but it’s not full time just yet…</h2>
              
              <div className="home__intro-body">
                <p>Home & Away Wins has now finished, but don’t worry! </p>
                <p>We’ve got an EPIC prize draw with the chance to win a 1x <strong>{currency}250</strong> {voucher} Voucher to wrap up this summer season!</p>
              </div>
              <div className="home__cta">
                {renderEntryCta()}
              </div>
            </div>
          )
        }
          
        </>

      case 'NotStarted':
        return <>
          <div className="home__intro home__intro--wide">
            <h2 className='type-h2'>Win a meet with a football hero and score an epic holiday</h2>
            <h3 className='type-h3'>Kick off 22nd April 2024</h3>
            <div className='home__intro-body'>
              <p>
              This is your chance to meet, online or in person, Virgil van Dijk, Leah Williamson, Harry Kane or Neymar Jr (with 3 of your mates) & WIN a holiday worth £/€5K. Plus a chance to win 1000s more prizes, including signed shirts and personalised player videos! <b>Enter when we kick off on April 22nd!</b>
              </p>
            </div>
          </div>

          <div className="home__cta">
            <Button
                wide
                type="submit"
                title="Visit Cadbury FC"
                onClick={() => setShowCountryCtaModal(true)}
              />
          </div>

        </>

      case 'Closed':
        return <>
          <div className="home__intro">

            <h2 className='type-h3 type-h3--white'>And... that's full time on Home & Away Wins!</h2>
            <div className="home__intro-body">
              <p>
              Our world-class summer promotion is over, but you still have a shot at winning match day tickets and other football goodies! Sign up for a Cadbury FC account to find out more.
              </p>
            </div>
          </div>
          <div className="home__cta">
            <Button
              wide
              type="submit"
              title="Visit Cadbury FC"
              onClick={() => setShowCountryCtaModal(true)}
              />
          </div>
        </>
    }
  }

  return (
    <div className="layout">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home | Cadbury FC - Home & Away Wins</title>
      </Helmet>
      {competitionIsOpen &&
        <>
          <CodeEntry
            show={showEntryModal}
            onDismiss={() => setShowEntryModal(false)}
            onEntry={() => history.push(!competitionIsMopUp ? "/prize-select":"/registration") } 
          />

          <CountrySelect
            show={showCountryModal}
            onCountrySelected={handleCountrySelected}
          />
        </>
      }

      {
        !competitionIsOpen && 
          <CountryCtaModal
            show={showCountryCtaModal}
            onDismiss={() => setShowCountryCtaModal(false)}
            competitionIsClosed={competitionIsClosed}
          /> 
      }

      <h1 className="visually-hidden">Cadbury's Dairy Milk - Home & Away Wins</h1>

      <section className="layout-wrapper home__banner">
        <div className="home__banner-container">
          <div className="home__banner-content">
            <KeyVisual />
          </div>
        </div>
      </section>

      
        <section className={"layout-wrapper home__entrybar relative " + (competitionIsOpen && !competitionIsMopUp ? "home__entrybar--wide" : "")}>
          <div className="layout-container layout-container--center">

            {renderIntroMessage(compState)}
            
          </div>
        </section>

        
        {(competitionIsOpen && !competitionIsMopUp) &&
          <div id="confetti-container" className="relative home__confetti-section bg-secondary">
          <section className="layout-wrapper home__prize-block-section">
            <div className="layout-container layout-container--center">
              <h2 className="type-h1">Top Tier Prize</h2>

              <div className="home__prize-blocks ">
                

                  <PrizeBlockDouble
                    image={PrizeTier}
                    alt="hand shake graphic"
                    title="Home Win"
                    description={`<p>There are 4 x player experiences up for grabs to meet one of our Cadbury FC heroes for you & 3 mates! Prize includes a <strong>60 minute player experience either in person or online.</strong></p>`}
                    image2={PrizeTier2}
                    alt2="globe with map pin graphic"
                    title2="Away Win"
                    description2={`<p>Winners will also get a <strong>` + ` ${currency}` + `5,000 holiday voucher</strong> to spend on a holiday of their choosing. WIN, WIN.</p>`}
                  />

                
              </div>

              <h2 className="type-h1">1000s of other prizes too</h2>

              <div className="home__prize-blocks ">
                

                    <PrizeBlock
                      imageMob={SignedShirtMob}
                      imageMobLarge={SignedShirtMob2x}
                      image={SignedShirt}
                      imageLarge={SignedShirt2x}
                      title="Epic Player Prizes"
                      description={`<p>While you’re waiting for the big one, you’re also entered to WIN a personalised video OR a signed shirt (Home or Away) from one of our four epic players. Prizes will be randomly allocated.</p>`}
                      alt="Signed shirt and video player graphic"
                    />


                    <PrizeBlock
                      imageMob={VouchersMob}
                      imageMobLarge={VouchersMob2x}
                      image={Vouchers}
                      imageLarge={Vouchers2x}
                      title="& Lifestyle Vouchers"                  
                      description={`<p>We have 1000s of vouchers up for grabs for high street retailers and leading brands that you can treat yourself to.</p>`}
                      alt="10 and 5 pound/euros vouchers"
                   />

                
              </div>
            </div>
          </section>

          <canvas id="confetti-canvas" className="home__entrybar-confetti" width="100%" height="100%" />
        </div>
        }

      {
        (competitionIsOpen) &&
        <section className={"layout-wrapper " + (competitionIsMopUp ? "bg-secondary" : "")} id="how-to-play">
          <div className="layout-container layout-container--center ">
            <h2 id="howitworks" className="type-h1">How does it work?</h2>
            <div className={(settings.country === "ROI" ? "home__steps home__steps--wide": "home__steps")}>
            {
                (!competitionIsMopUp) ? (

                  
                    (settings.country === "ROI") ?
                      (
                        renderSteps([
                          { number: 1, title: "Pick your player", image: How4, image2x: How4_2x, alt: "Virgil van Dijk, Leah Williamson, Harry Kane, and Neymar Jr." },
                          { number: 2, title: "Enter your personal details", image: How1, image2x: How1_2x, alt: "Form Graphic." },
                          { number: 3, title: "Find out on-screen if you've won an epic player prize or voucher", image: How6, image2x: How6_2x, alt: "Home and Away Wins.", description: "Main prize draw will take place after 19/07/2024" },
                        ])
    
                      ) : (
                        renderSteps([
                          { number: 1, title: "Buy a Promotional Product", image: How5, image2x: How5_2x, alt: "Cadbury products", descriptionLink: <><a href="/terms-and-conditions#products-list" rel="noopener noreferrer" target='_blank'>See full product list here</a></> },
                          { number: 2, title: "Enter the barcode and batch code of your product", image: How2, image2x: How2_2x, alt: "A barcode and batch code from a Cadbury pack" },
                          { number: 3, title: "Pick your player", image: How4, image2x: How4_2x, alt: "Virgil van Dijk, Leah Williamson, Harry Kane, and Neymar Jr." },
                          { number: 4, title: "Enter your personal details", image: How1, image2x: How1_2x, alt: "Form Graphic." },
                          { number: 5, title: "Find out on-screen if you've won an epic player prize or voucher", image: How6, image2x: How6_2x, alt: "Home and Away Wins.", description: "Main prize draw will take place after 19/07/2024" }
                        ])
                      )
    
                  

                  ) : (
                    
                      (settings.country === "ROI") ?
                        (
                          renderSteps([
                            { number: 1, title: "Enter your personal details", image: How1, image2x: How1_2x, alt: "Form Graphic." },
                            { number: 2, title: "Find out at the end if you've won a €250 voucher", image: How3, image2x: How3_2x, alt: "€250 e-voucher.", description: "Prize draw will take place on 24.01.25" },
                          ])
      
                        ) : (
                          renderSteps([
                            { number: 1, title: "Buy a promotional product", image: How5, image2x: How5_2x, alt: "Cadbury products", descriptionLink: <><a href="/terms-and-conditions#products-list" rel="noopener noreferrer" target='_blank'>See full product list here</a></> },
                            { number: 2, title: "Enter the barcode and batch code of your product", image: How2, image2x: How2_2x, alt: "A barcode and batch code from a Cadbury pack" },
                            { number: 3, title: "Follow the instructions to see if you're a winner!", image: How3, image2x: How3_2x, alt: "£250 e-voucher.", description: "Prize draw will take place on 24.01.25" },
                          ])
                        )
      
                    
                  )
            }
            
            </div>
            <div className="home__cta">
              {renderEntryCta()}
            </div>
          </div>
        </section>
      }

      {
        (competitionIsNotStarted || competitionIsOpen) &&
        <SummaryTsandCs className={(competitionIsMopUp ? "bg-secondary" : "")} />
      }

    </div >
  );
}