import React from 'react';
import classList from 'react-classlist-helper';

import BadgeBorderSec from 'assets/images/campaign/badges/badge-border-darkpurple.png';
import BadgeBorderSec2 from 'assets/images/campaign/badges/badge-border-darkpurple@2x.png';
import BadgeBorder from 'assets/images/campaign/badges/badge-border-purple.png';
import BadgeBorder2 from 'assets/images/campaign/badges/badge-border-purple@2x.png';

import './PlayerBadge.scss';

const PlayerBadge = (props) => {
  const classMap = {
    "player-badge": true,
    "player-badge--secondary" : props.type === "secondary"
  };

  const imageClassMap = {
    "player-badge__image": true
  };


  return (
    <div className={classList(classMap)}>
      <div className={classList(imageClassMap)}>


          {
        props.prize.image2x ? (
          <img className="player-badge__player" src={props.prize.image} srcSet={props.prize.image2x + " 2x, " + props.prize.image + " 1x"} alt={props.prize.title} />
        ) : (
          <img className="player-badge__player" src={props.prize.image} alt={props.prize.title} />
        )
      }

        {
        props.type === "secondary" ? (
          <img className="player-badge__border" src={BadgeBorderSec} srcSet={BadgeBorderSec2 + " 2x, " + BadgeBorderSec + " 1x"} alt="" />
        ) : (
          <img className="player-badge__border" src={BadgeBorder} srcSet={BadgeBorder2 + " 2x, " + BadgeBorder + " 1x"} alt="" />
        )
      }
      

      </div>
      <h3 className="player-badge__title"><span>{props.prize.title}</span></h3>
    </div>
  )
}

export default PlayerBadge;
