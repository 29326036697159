//import React from 'react';
import { Redirect } from 'react-router-dom';
import React, { useState } from 'react';
import ForgottenPasswordConfirm from './ForgottenPasswordConfirm';

import useSettingsContext from 'contexts/useSettingsContext';

export default () => {

  const settings = useSettingsContext();
  let compState = settings.competitionStatus.openState;
  let competitionIsOpen = compState === "Open";
  let isIreland = settings.country === "ROI";
  const parsedUrl = new URLSearchParams(window.location.search); 
  

  if (!settings.isLoggedIn && competitionIsOpen && parsedUrl.get('oobCode') != null && parsedUrl.get('oobCode') != "") {    
    return <ForgottenPasswordConfirm />
  } else {
    return <Redirect to='/' />
  }
}