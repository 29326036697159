import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Button from 'components/Button/Button';
import SettingsContext from 'contexts/SettingsContext';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import competitionEntryService from 'services/competitionEntryService';
import analyticsService from 'services/analyticsService';
import SignUp from 'containers/Forms/SignUp';
import useEffectOnce from 'hooks/useEffectOnce';
import PlayerBadge from 'components/PlayerBadge/PlayerBadge';

import Stage from 'components/Confetti/Stage';
import NpsBanner from 'components/NpsBanner/NpsBanner';
import timer from 'utils/timer';

//winner vouchers
import WinnerVoucher5 from 'assets/images/campaign/prizes/voucher-5.png';
import WinnerVoucher5__2x from 'assets/images/campaign/prizes/voucher-5@2x.png';
import WinnerVoucher10 from 'assets/images/campaign/prizes/voucher-10.png';
import WinnerVoucher10__2x from 'assets/images/campaign/prizes/voucher-10@2x.png';

//winner shirt
import WinnerShirt from 'assets/images/campaign/prizes/shirt.png';
import WinnerShirt__2x from 'assets/images/campaign/prizes/shirt@2x.png';

//video image with player
import WinnerVideo__will from 'assets/images/campaign/prizes/vid-will.png';
import WinnerVideo__will__2x from 'assets/images/campaign/prizes/vid-will@2x.png';
import WinnerVideo__kan from 'assets/images/campaign/prizes/vid-kan.png';
import WinnerVideo__kan__2x from 'assets/images/campaign/prizes/vid-kan@2x.png';
import WinnerVideo__ney from 'assets/images/campaign/prizes/vid-ney.png';
import WinnerVideo__ney__2x from 'assets/images/campaign/prizes/vid-ney@2x.png';
import WinnerVideo__vir from 'assets/images/campaign/prizes/vid-vir.png';
import WinnerVideo__vir__2x from 'assets/images/campaign/prizes/vid-vir@2x.png';

import prizeList from 'utils/prizes';

import './Thankyou.scss';


export default () => {
  let { entryPublicId } = useParams();
  //let prizePublicId = "signed-shirt-kan";
  //let prizePublicId = "video-kan";
  // let prizePublicId = "voucher-5";

  let [settings, setSettings] = React.useContext(SettingsContext);
  let compState = settings.competitionStatus.openState;
  let compPhaseId = settings.competitionStatus.currentPhasePublicId;
  let competitionIsOpen = compState === "Open";
  let competitionIsMopup = compPhaseId === "mop-up";

  let competitionEntryContext = useCompetitionEntryContext();
  let [entry, setEntry] = useState(competitionEntryContext.result);

  let [showNps, setShowNps] = useState(null);

  let [currency, setcurrency] = useState('');
  let [prizeText, setPrizeText] = useState('');

  useEffect(() => {
    switch (settings.country) {
      case "ROI":
        setcurrency("€");
        setPrizeText('AllGifts.IE voucher!');
        break;
      case "GB":
      case "NI":
      case "UK":
        setcurrency("£");
        setPrizeText('Lifestyle voucher!');
        break;
      default:
        setcurrency("£/€");
        setPrizeText('voucher!');
        break;
    }
  }, [settings.country]);



  useEffect(() => {
    async function fetchData() {
      await competitionEntryService.getCompetitionEntry(entryPublicId)
      .then((result) => {       
          if(result) {
            setEntry(result); 

            if(result.country && result.country !== undefined && result.country !== null){
              sessionStorage && sessionStorage.setItem('cadburyfc.country', result.country);
              settings.setCountry(result.country);
            }
          } 
      })
      .catch(err => console.log(err) );
    }

    if (entryPublicId && !entry) {
      fetchData();
    }

    var email = competitionEntryContext.email;
    //Had to go here, as not triggered on last page
    if (email != null) {
      // Save email to DataLayer - GTM uses this to pass to Lytics (Only if the user has accepted targeting cookies)

      analyticsService.logEvent("forms", "Forms", {
        action: "EmailCapture",
        email
      });

      analyticsService.logEvent("forms", "Forms", {
        action: "RegistrationSuccess"
      });

      if (entry.hasWon) {
        analyticsService.logEvent('event', 'Prize', {
          action: entry.prizePublicId
        });
      }
    }

    // STARTS CONFETTI 
    var isDesk = window.matchMedia("only screen and (min-width: 768px)").matches;
    var isLargeDesk = window.matchMedia("only screen and (min-width: 1600px)").matches;
    var conffetiNodes = 70;

    if (isLargeDesk) {
      conffetiNodes = 200;
    } else if (isDesk) {
      conffetiNodes = 100;
    }
    
    if (entry && entry.hasWon) {
      start();
      return () => Stage.destroy()
    }

    async function confetti() {
      Stage.init({
        nodeCount: conffetiNodes,
        typeGold: true
      });
    }

    async function start() {
      await timer.delay(500);
      confetti();
    }
    // ENDS CONFETTI     

  }, [entry]);


  useEffectOnce(() => {
    timer.delay(2500).then(() => setShowNps(true));
  });

 
  if (competitionIsOpen && ((entry !== null && !entry.entryPublicId) || !entryPublicId)) {
    return (
      <Redirect to="/" />
    )
  }

  const renderSignupComponent = (isLogged) => {
    return (
      <div className="thankyou__signup">
        <SignUp
          competitionEntryPublicId={entryPublicId}
          email={competitionEntryContext.email}
          isLogged={isLogged}
          title="More opportunities to win"
          description={
            isLogged ?
              (`Head over to Cadbury FC to see what other prizes you can win with your Cadbury FC account throughout the year.`)
              :
              (`Create a Cadbury FC account for additional chances to WIN match day tickets and more, plus receive email updates with the latest news and competitions. It's quick to set up an account – we can use the details you've just shared.`)
          }
          submitText="Errm, yes please"
        />
      </div>
    )
  }

  const renderNpsBanner = (entryPublicId) => {
    if (!entryPublicId) return null;
    return <NpsBanner onClose={() => setShowNps(false)} competitionEntryPublicId={entryPublicId} show={showNps} />
  }

  const RenderPlayerBadges = (prizeId) => {
    return (
      <>
        {
          {
            "signed-shirt-kan":
              <PlayerBadge
                prize={prizeList.filter((item) => { return item.id === "KAN"; })[0]}
              />,
            "signed-shirt-vir":
              <PlayerBadge
                prize={prizeList.filter((item) => { return item.id === "VIR"; })[0]}
              />,
            "signed-shirt-wil":
              <PlayerBadge
                prize={prizeList.filter((item) => { return item.id === "WIL"; })[0]}
              />,
            "signed-shirt-ney":
              <PlayerBadge
                prize={prizeList.filter((item) => { return item.id === "NEY"; })[0]}
              />
          }[prizeId]
        }
      </>
    );
  }


  const renderWinner = () => {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Congratulations! | Cadbury FC - Home & Away Wins</title>
        </Helmet>

        <section id="confetti-container" className="layout-general layout-wrapper layout-general--full layout-general--conffeti thankyou">
          <div className="layout-container layout-container--center layout-container--slim">

            {
              (entry.prizePublicId.indexOf('signed-shirt') > -1) ? (
              // (prizePublicId.indexOf('signed-shirt') > -1) ? (
                <h1 className='type-h1'>Goal!<br />You are a winner!</h1>
              ) :
                (entry.prizePublicId.indexOf('video') > -1) ? (
                // (prizePublicId.indexOf('video') > -1) ? (
                  <h1 className='type-h1'>Back of the net! You're a winner!</h1>
                ) : (
                  <h1 className='type-h1'>You Shoot. You score! Now claim your prize!</h1>
                )
            }

            <div className={entry.prizePublicId.indexOf('signed-shirt') > -1 ? 'thankyou__row' : 'thankyou__row thankyou__row--fullwidth'}>
            {/* <div className={prizePublicId.indexOf('signed-shirt') > -1 ? 'thankyou__row' : 'thankyou__row thankyou__row--fullwidth'}> */}
              {
                (entry.prizePublicId.indexOf('signed-shirt') > -1) &&
                // (prizePublicId.indexOf('signed-shirt') > -1) &&
                <div className='thankyou__row-column'>
                  {
                    RenderPlayerBadges(entry.prizePublicId)
                    //RenderPlayerBadges(prizePublicId)
                  }
                </div>
              }

              <div className='thankyou__row-column'>
                {
                  {
                    "voucher-5": <img className="thankyou__img thankyou__img--wider" src={WinnerVoucher5} srcSet={WinnerVoucher5__2x + " 2x, " + WinnerVoucher5 + " 1x"} alt="£/€10 voucher" />,
                    "voucher-10": <img className="thankyou__img thankyou__img--wider" src={WinnerVoucher10} srcSet={WinnerVoucher10__2x + " 2x, " + WinnerVoucher10 + " 1x"} alt="£/€5 voucher" />,
                    "signed-shirt-ney": <img className="thankyou__img" src={WinnerShirt} srcSet={WinnerShirt__2x + " 2x," + WinnerShirt + " 1x"} alt="Football shirt" />,
                    "signed-shirt-wil": <img className="thankyou__img" src={WinnerShirt} srcSet={WinnerShirt__2x + " 2x, " + WinnerShirt + " 1x"} alt="Football shirt" />,
                    "signed-shirt-vir": <img className="thankyou__img" src={WinnerShirt} srcSet={WinnerShirt__2x + " 2x," + WinnerShirt + " 1x"} alt="Football shirt" />,
                    "signed-shirt-kan": <img className="thankyou__img" src={WinnerShirt} srcSet={WinnerShirt__2x + " 2x, " + WinnerShirt + " 1x"} alt="Football shirt" />,
                    "video-ney":<img className="thankyou__img" src={WinnerVideo__ney} srcSet={WinnerVideo__ney__2x + " 2x, " + WinnerVideo__ney + " 1x"} alt="Player badge of Neymar Jr with video player" />,
                    "video-wil":<img className="thankyou__img" src={WinnerVideo__will} srcSet={WinnerVideo__will__2x + " 2x, " + WinnerVideo__will + " 1x"} alt="Player badge of Leah Williamson with video player" />,
                    "video-vir":<img className="thankyou__img" src={WinnerVideo__vir} srcSet={WinnerVideo__vir__2x + " 2x, " + WinnerVideo__vir + " 1x"} alt="Player badge of Virgil van Dijk with video player" />,
                    "video-kan":<img className="thankyou__img" src={WinnerVideo__kan} srcSet={WinnerVideo__kan__2x + " 2x, " + WinnerVideo__kan + " 1x"} alt="Player badge of Harry Kane with video player" />,
                   }[entry.prizePublicId]
                   //}[prizePublicId]
                }
              </div>
            </div>

            <div className='thankyou__textcont'>
              
              <h2 className="type-h2">Congrats!</h2>
              <div className="thankyou__para">
                {
                  {
                    "voucher-5": <p>You've provisionally* won a <b>{currency}5 {prizeText}</b></p>,
                    "voucher-10": <p>You've provisionally* won a <b>{currency}10 {prizeText}</b></p>,
                    "signed-shirt-ney": <p>You've provisionally* won a <b>signed shirt! from Neymar Jr</b></p>,
                    "signed-shirt-wil": <p>You've provisionally* won a <b>signed shirt! from Leah Williamson</b></p>,
                    "signed-shirt-vir": <p>You've provisionally* won a <b>signed shirt! from Virgil van Dijk</b></p>,
                    "signed-shirt-kan": <p>You've provisionally* won a <b>signed shirt! from Harry Kane</b></p>,
                    "video-kan": <p>You've provisionally* won an <b>exclusive personalised message from Harry Kane</b></p>,
                    "video-wil": <p>You've provisionally* won an <b>exclusive personalised message from Leah Williamson</b></p>,
                    "video-vir": <p>You've provisionally* won an <b>exclusive personalised message from Virgil van Dijk</b></p>,
                    "video-ney": <p>You've provisionally* won an <b>exclusive personalised message from Neymar Jr</b></p>,
                  }[entry.prizePublicId]
                  // }[prizePublicId]
                }

                <p>
                  In order to validate your entry and claim your prize, you'll be sent a winning confirmation email to the email address you registered. If you don't validate your entry by clicking the unique link in the email and completing your claim details within <strong>14 days</strong> from the date of this online win notification, unfortunately you will forfeit your prize, so don't forget to check your junk folder!
                  {
                     (entry.prizePublicId.indexOf('signed-shirt') > -1) ? (" Delivery of shirt could take up to 6 months. ") : (
                      (entry.prizePublicId.indexOf('voucher') > -1) ? (" Please note postage and packaging fees may apply on the purchase you make with your voucher. ") : ("")
                     )
                  }

                </p>
                
                <p>
                  If you do not receive an email please contact <a href="mailto:cadburyhomeaway@promowinners.com" target="_blank" rel="noopener noreferrer">cadburyhomeaway@promowinners.com</a>.
                </p>
                <p>You've also been entered into the draw to WIN a meet, online or in person, with your favourite world-class player out of Virgil van Dijk, Leah Williamson, Harry Kane or Neymar Jr & an epic holiday to a destination of your choosing worth {currency}5k. The draw will take place at the end of the promotion. Fingers crossed you bag another WIN!</p>
              </div>
            </div>

              {renderSignupComponent(settings.isLoggedIn)}
              <p className="thankyou__para thankyou__para--msg">*Subject to eligibility and verification checks.</p>

          </div>

          {renderNpsBanner(entryPublicId)}
          <canvas id="confetti-canvas" className="layout-general__confetti" width="100%" height="100%" />
        </section>
      </>
    );

  }

  const renderLoser = () => {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Sorry | Cadbury FC - Home & Away Wins</title>
        </Helmet>

        <section className="layout-general layout-wrapper layout-general--full thankyou">
          <div className="layout-container layout-container--center layout-container--slim">
            <h1 className="type-h2 type-h2--smlmargin">Sorry, You haven't scored this time. But it's still all to play for!</h1>
            <p className="thankyou__para">
              You haven't scored anything just yet, but don't worry! You're still in with a chance to WIN a meet, online or in person, with your favourite football hero out of Virgil van Dijk, Leah Williamson, Harry Kane or Neymar Jr and an epic holiday away worth {currency}5K! The draw will take place at the end of the promotion. Fingers crossed you bag a WIN!
            </p>
            <p className="thankyou__para">
              And remember… you can still have another shot tomorrow.* Buy a pack to enter again. Good luck!
            </p>

            {renderSignupComponent(settings.isLoggedIn)}

            <p className="thankyou__para ">
              *Maximum of 20 entries per person during the entire Promotion Period.
            </p>
          </div>

          {renderNpsBanner(entryPublicId)}
        </section>
      </>
    );
  }

  const renderMopUp = () => {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Thank you | Cadbury FC - Home & Away Wins</title>
        </Helmet>

        <section className="layout-general layout-wrapper">
          <div className="layout-container layout-container--center layout-general--full thankyou">
            <h1 className="type-h2">Thank you</h1>
            <p className="thankyou__para">
              We'll be in touch if you're a lucky winner.
            </p>
            {renderSignupComponent(settings.isLoggedIn)}
            
          </div>
        </section>
      </>
    )
  }


  return (
    <>
    {
      (entryPublicId && !entry) ? (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Error | Cadbury FC - Home & Away Wins</title>
          </Helmet>
          <section className="layout-general layout-wrapper">
            <div className="layout-container layout-container--center layout-general--full thankyou">
              <h1 className="type-h2">Error!</h1>
              <p className="thankyou__para">
                Sorry, something went wrong.
              </p>
              <Button title="Back to homepage" href="/" className="mt-20" />
            </div>
          </section>
        </>
      ) : (

        competitionIsMopup ? (
          renderMopUp()
        ) : (
          entry.hasWon ? (
            renderWinner()
          ) : (
            renderLoser()
          )
        )
      )
    }

    </>

  );
}
