import React, { useState } from 'react';
import classList from 'react-classlist-helper';
import npsService from 'services/npsService';
import { ReactComponent as Star } from 'assets/images/campaign/star.svg';
import './NpsBanner.scss';

import analyticsService from 'services/analyticsService';

export default (props) => {
  const [star, setStar] = useState(false);
  const [selected, setSelected] = useState(false);
  const classMap = {
    "nps": true,
    [`nps--star${star}`]: true,
    "nps--show": props.show
  };

  const renderStars = () => {
    const stars = ["Very bad", "Bad", "Ok", "Good", "Very good"];
    const classMap = {
      "nps__star": true,
      [`nps__star--active${star}`]: true
    };

    return stars.map((star, index) => {
      return (
        <button key={`nps-star--${index}`} title={star} className={classList(classMap)} onMouseOut={() => setStar(false)} onMouseOver={() => handleStarOver(index+1)} onClick={() => handleStarClick(index+1)}>
          <span>{ star }</span>
          <Star />
        </button>
      )
    })
  }
  
  const handleStarOver = (star) => {
    !selected && setStar(star);
  }

  const handleStarClick = (star) => {
    //console.log(props.competitionEntryPublicId)
    setSelected(true);
    npsService
      .addNpsScore(props.competitionEntryPublicId, star)
      .then(() => props.onClose());

    analyticsService.logEvent("NPS", "NPSBanner", {
      action: "SetNPSRate",
      label: star
    });
  }

  const handleNPSClose = () => {
    props.onClose();
    analyticsService.logEvent("NPS", "NPSBanner", {
      action: "CloseNPSBanner",
      label: "UnsetRate"
    });
  }

  return (
    <div className={classList(classMap)}>
      <h3 className="nps__title">Please rate your experience of entering Home & Away Wins</h3>
      <div className="nps__stars">
        <span className="nps__key nps__key--bad">Very bad</span>
        { renderStars() }
        <span className="nps__key nps__key--good">Very good</span>
      </div>
      <button className="nps__close" onClick={handleNPSClose}>close</button>
    </div>
  )
}