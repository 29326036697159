import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import prizeClaimService from 'services/prizeClaimService';
import prizeClaimValidator from 'validators/prizeClaimValidator';

import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';

import Button from 'components/Button/Button';
import TextField from 'components/Forms/TextField';
import ValidationMessage from 'components/Validation/ValidationMessage';
import ValidationSummary from 'components/Validation/ValidationSummary';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';

import ReCaptcha from 'components/Forms/ReCaptcha';
import Modal from 'components/Modal/Modal';
import AlertIcon from 'assets/images/alert.png';
import Link from 'components/Link/Link';

import Form, { createOnChangeHandler } from './Form';
import analyticsService from 'services/analyticsService';

import ClaimedImage from 'assets/images/campaign/claim-prizes.png';
import ClaimedImage2x from 'assets/images/campaign/claim-prizes@2x.png';

import './Claim.scss';
import './Form.scss';


export default (props) => {
  let settingsContext = useSettingsContext();
  let compPhaseId = settingsContext.competitionStatus.currentPhasePublicId;
  let [settings, setSettings] = React.useContext(SettingsContext);
  let [token, setToken] = useState(props.token);
  let [otpAvilable, setOtpAvilable] = useState(props.otpAvilable);
  let [otp, setOtp] = useState(props.otpAvilable);
  let [claim, setClaim] = useState();  
  let [sent, setSent] = useState(false);
  let [firstName, setFirstName] = useState('');
  let [lastName, setLastName] = useState('');
  let [address1, setAddress1] = useState('');
  let [address2, setAddress2] = useState('');
  let [town, setTown] = useState(null);
  let [county, setCounty] = useState(null);
  let [country, setCountry] = useState(null);
  let [postcode, setPostcode] = useState(null); 
  let [phasePublicId, setPhasePublicId] = useState('');

  let [recaptcha2, setRecaptcha2] = useState(null);

  let [prizePublicId, setPrizePublicId] = useState("");
  let [prizeCategory, setPrizeCategory] = useState('');


  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });


  
  
  
  useEffect(() => {
    let account = settings.account;
    if (!account) return;

    if (account.firstName) {
      setFirstName(account.firstName);
    }

    if (account.lastName) {
      setLastName(account.lastName);
    }

    if (account.address1) {
      setAddress1(account.address1);
    }

    if (account.address2)  {
      setAddress2(account.address2);
    }

    if (account.city) {
      setTown(account.city);
    }

    if (account.county) {
      setCounty(account.county);
    }

    if (account.postcode) {
      setPostcode(account.postcode);
    }
    
  }, [settings.account])

  
  const createCommand = () => {
    let command = {
      firstName,
      lastName,
      address1,
      address2,
      town,
      county,
      country,
      postcode : postcode ? postcode.toUpperCase() : postcode,
      token,
      recaptcha2,
      phaseId: phasePublicId,
      prizePublicId,
      otpAvilable: props.otpAvilable ? props.otpAvilable : false,
      otp: props.otp ? props.otp : "",
      flowId: props.flowId ? props.flowId : null,
      entryFlowToken: props.entryFlowToken ? props.entryFlowToken : null
    };

    return command;
  }

  var handleReCaptchaV2 = (val) => {
    if(val !== null && val !== ""){
      createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2, val)
    }
  }

  var handleReCaptchaV2Expired = () => {
    setRecaptcha2(null);
  }

  useEffect(() => {   
      if(claim !== undefined && claim.canClaim !== undefined && claim.canClaim === true)
      {
        window.setTimeout(function(){
          try 
          {
            let $grecaptchav2 = document.querySelectorAll('.g-recaptcha-v2')[0];
            if ($grecaptchav2) {
             
                window.grecaptcha.ready(() => {
                    window.grecaptcha.render($grecaptchav2, {
                  'sitekey' : '6LeSFWApAAAAAHf7x_em5yVBSpdiqq6ZVW6Nrvwk',
                  'callback': handleReCaptchaV2,
                    'expired-callback': handleReCaptchaV2Expired
                    });
              });
            }
          }catch{}

        }, 1000);
      
      }
    
  }, [claim]);


  useEffect(() => {
    document.body.classList.remove("overflow-hidden-mediumdown");
    async function fetchData() {
      let result = await prizeClaimService.getPrizeClaim(token);
      if(result) {
        setClaim(result);
        
        if(result.prize) {
          setPrizeCategory(result.prize.category.title);
          setPrizePublicId(result.prize.publicId);          
        }
        setCountry(result.country);
        setPhasePublicId(result.phasePublicId);
      }
    }

    if (token && !claim) {
      fetchData();
    }
  }, [claim, token]);

  if (token && !claim) {
    return (
      <div>Loading...</div>
    )
  }



const onFormSuccess = () => {
  analyticsService.logEvent("form", "ClaimForm", {
    action: "SubmitClaimForm",
    label: prizeCategory
  });

  return setSent(true);
}

const onDismissModal = () => {

  setValidation(prevState => ({ ...prevState, acknowledged: true }));
  
  document.body.classList.remove("overflow-hidden-mediumdown");
}


  if (!claim.canClaim) {
    return (
      <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Sorry! | Cadbury FC - Home & Away Wins</title>
      </Helmet>
        <section className="layout-general layout-general--full layout-wrapper">
          <div className="layout-container layout-container--slim layout-container--center">
            <h1 className="type-h1">Sorry!</h1>
            <div className="form__intro">
              <p >{claim.error.message}</p>
            </div>  
          </div>
        </section>
      </>
    )
  }

  return (  
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Claim your prize | Cadbury FC - Home & Away Wins</title>
    </Helmet>
    <section className="layout-general layout-general--full layout-wrapper">
      <div className="layout-container layout-container--slim layout-container--center claim">
        { !sent ? 
          (
            <>
            
            <h1 className="type-h1">Congratulations!</h1>
            <div className="form__intro">
              <p>Please complete your details below for win verification and prize delivery purposes.</p> 
              <p>Fields marked with * are mandatory</p>
            </div>
                
            
            <Form 
              id="claim"
              submitTitle={"Claim"}
              formClass="form form--slim"
              onSuccess={onFormSuccess}
              command={{
                model: createCommand,
                service: prizeClaimService.addPrizeClaim
              }}
              validation={{
                validator: prizeClaimValidator,
                onValidation: setValidation
              }}
             
            >
              
              { validation.errors.hasErrors && validation.acknowledged &&
                <ValidationSummary 
                  errors={validation.errors}
                  handlePropertyErrors={['RecaptchaToken']}
                />
              }
              <fieldset className="form__fieldset">
                <TextField 
                  label="* First Name"
                  id="firstName"
                  onChange={createOnChangeHandler(validation, 'firstName', setFirstName)}
                  value={firstName}
                  hasErrors={!!validation.errors.firstName}
                />
                <ValidationMessage errors={validation.errors.firstName} />
              </fieldset>
              <fieldset className="form__fieldset">
                <TextField 
                  label="* Last Name"
                  id="lastName"
                  onChange={createOnChangeHandler(validation, 'lastName', setLastName)}
                  value={lastName}
                  hasErrors={!!validation.errors.lastName}
                />
                <ValidationMessage errors={validation.errors.lastName} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextField 
                  label="* Address line 1"
                  id="address1"
                  onChange={createOnChangeHandler(validation, 'address1', setAddress1)}
                  value={address1}
                  hasErrors={!!validation.errors.address1}
                />
                <ValidationMessage errors={validation.errors.address1} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextField 
                  label="Address line 2 (optional)"
                  id="address2"
                  onChange={createOnChangeHandler(validation, 'address2', setAddress2)}
                  value={address2}
                  hasErrors={!!validation.errors.address2}
                />
                <ValidationMessage errors={validation.errors.address2} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextField
                  label="* Town/City"
                  id="town"
                  onChange={createOnChangeHandler(validation, 'town', setTown)}
                  value={town}
                  hasErrors={!!validation.errors.town}
                />
                <ValidationMessage errors={validation.errors.town} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextField 
                  label="* County"
                  id="county"
                  onChange={createOnChangeHandler(validation, 'county', setCounty)}
                  value={county}
                  hasErrors={!!validation.errors.county}
                />
                <ValidationMessage errors={validation.errors.county} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextField 
                  label={ country === "ROI" ? `* Eircode`:`* Postcode` }
                  id="postcode"
                  onChange={createOnChangeHandler(validation, 'postcode', setPostcode)}
                  value={postcode}
                  hasErrors={!!validation.errors.postcode}
                />
                <ValidationMessage errors={validation.errors.postcode} />
              </fieldset>       


              <ReCaptcha captchaSettings={settingsContext.captchaSettings} />    
              <div class="g-recaptcha-v2" data-sitekey="6LeSFWApAAAAAHf7x_em5yVBSpdiqq6ZVW6Nrvwk"
                data-load={createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2)}></div>
              <ValidationMessage errors={validation.errors.recaptcha2} /> 

              { validation.errors.hasErrors && !validation.acknowledged &&
                <Modal 
                  panel 
                  hideClose
                  icon={AlertIcon}
                  alt="Warning sign"
                  onDismiss={onDismissModal}
                  onConfirm={onDismissModal}
                  title="Oops">
                  <ValidationSummary 
                    modal
                    defaultMessage="Please check you have entered all fields correctly."
                    errors={validation.errors}
                    handlePropertyErrors={['RecaptchaToken']}
                  />
                  { !validation.errors.globalErrors.length ? (
                      <Button title="Try again" onClick={onDismissModal} />
                    ) : (
                      <Button title="Back to homepage" href="/" />
                    )
                  }
                </Modal>
              }
            </Form>
            
            
            </>
          ) : (
            <>
              <h1 className="type-h1">Thank you!</h1>
              <p className="form__intro">
                Your prize will be winging its way to you shortly, once all your details have been verified. 
                </p>
              <img className="claim__image" src={ClaimedImage} srcSet={ClaimedImage2x + " 2x, " + ClaimedImage + " 1x"}
              alt="Home and Away Wins prizes: signed shirt, video player and vouchers." />
              <Link to="https://www.cadbury.co.uk/cadburyfc/" lozenge>Go to Cadbury FC</Link>
            </>
          )
        }
        
      </div>
    </section>
    </>
  );
}