import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import Accordion from 'components/Accordion/Accordion';
import './FAQs.scss';
import { HashLink as Link } from 'react-router-hash-link';


export default (props) => {
  let defaultFaq = { section: 1, index: 0 };
  if (window.location.hash.length) {
    let values = window.location.hash.replace('#','').split('-');
    defaultFaq = {
      section: parseInt(values[0]),
      index: parseInt(values[1])
    }
  }

  const [accordion, setAccordion] = useState(defaultFaq);

  const handleAccordionClick = (index) => {
    if (index.index === accordion.index && 
        index.section === accordion.section) index = { section: 0, index: 0 };
    setAccordion(index);
  }

  const renderFaqs = (section, faqs) => {
    return faqs.map((faq, index) => {
      let id = { section, index };
      let key =  `${id.section}-${id.index}`;

      return (
        <Accordion
          id={key}
          key={key}
          open={`${accordion.section}-${accordion.index}` === key}
          onClick={handleAccordionClick.bind(this, id)}
          title={faq.question}
          answer={faq.answer}>
        </Accordion>
      )
    })
  }

  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>FAQs | Cadbury FC - Home & Away Wins</title>
    </Helmet>
    <section className="layout layout-general layout-general--full layout-wrapper">
      <div className="layout-container layout-container--slim layout-container--center ">
        <h1 className="type-h1">FAQ<span className="lowercase">s</span></h1>
       {  <div className="faqs">
          <div className="faqs__section">
      
          
            <h2 className="type-h3">General promotional questions</h2>
            {
              renderFaqs(1, [{
                question: "1. What is the 'Home & Away Wins' promotion?",
                answer: `
                  <p>
                    Cadbury's 'Home & Away Wins' is a promotion that gives you the chance to win not one but two incredible prizes. A meet with a world-class player from our amazing line up, AND a 5 star trip away to a striking destination of your choice. Plus there are thousands of additional prizes to be won including signed shirts, personalised video messages and shopping vouchers. For a full breakdown of the prizes please see the T&Cs page <a href="/terms-and-conditions">here</a>.
                  </p>`
                },{
                question: "2. What is the 'Home & Away Wins' Wrap-up Draw?",
                answer: `
                <p>
                  <strong>UK residents:</strong> If you purchase a promotional product during the Wrap-up period (19th July 2024
                  to 19th January 2025), head to the website and follow the on-screen instructions,
                  you'll be entered into our Wrap-up prize draw and could be in for a chance to win a
                  £/€250 lifestyle voucher. For further information please see the T&Cs page <a href="/terms-and-conditions">here</a>.
                </p>
                <p>
                Entrants in ROI do not need a promotional product barcode to enter, in line with the NPN process.
                </p>
              `},{
                question: "3. Who is the promotion open to?",
                answer: `
                <p>
                  The promotion is open to anyone 18 and over who lives in the UK or ROI, excluding
                  the Channel Islands and the Isle of Man.
                </p>
                
              `},{
                question: "4. How can I enter/ How does it work?",
                answer: `
                <p>
                  To enter for the UK, simply buy any Promotional Product, head to
                  <a href="https://homeandaway.cadbury.co.uk" target="_blank" rel="noopener noreferrer">homeandaway.cadbury.co.uk</a>, select your region, enter the barcode and batch
                  code, select which of our epic players you'd like a chance to meet (Harry Kane,
                  Leah Williamson, Virgil van Dijk, Neymar Jr.) and enter your details. The barcode
                  and batch code can be found on the back of your participating Promotional
                  Product. If you'd like to review the full terms and conditions and product details
                  please click <a href="/terms-and-conditions#products-list">here</a>.
                </p>

                <p>
                  To enter for the Republic of Ireland, simply head to <a href="https://homeandaway.cadbury.ie" target="_blank" rel="noopener noreferrer">homeandaway.cadbury.ie</a>
                  select your region, the player you'd like to win a meet at HOME with & enter your
                  details including your Eircode.
                </p>

                <p>
                  You will find out instantly on screen if you have provisionally won a signed shirt,
                  personalised video message or shopping voucher (the win is provisional upon
                  passing verification checks). You will also be entered into a Prize draw for the
                  chance to meet (online or in-person depending on availability) your chosen player.
                  The Prize draw will take place within 5 working days after the promotion ends. The
                  promotion ends on the 19th July 2024.
                </p>
              `},{
                question: "5. Which products are included in the promotion?",
                answer: `
                <p>
                  A wide range of Cadbury chocolate, Cadbury biscuits, Maynards Bassetts sweets
                  and Trebor mints are included in the promotion. For a full list of participating
                  products please visit the T&Cs page <a href="/terms-and-conditions#products-list">here</a> and go to Appendix 1.
                </p>
              `},{
                question: "6. When can I enter?",
                answer: `
                <p>
                The promotion runs from 10:00 BST on 22 April 2024 to 16:59 BST on 19 July 2024.
                </p>
                <p>
                The wrap up draw runs from 17:00 BST on 19 July 2024 to 16:59 GMT on 19 January 2025.  
                </p>
              `},{
                question: "7. Do I need to keep my receipt for the participating product until the end of the promotion?",
                answer: `
                <p>
                  Yes, if you live in England, Scotland, Wales or Northern Ireland, proof of purchase
                  may be required to verify your claim - so don't throw it away.
                </p>
                <p>
                This will not be required if you live in the Republic of Ireland. 
                </p>
              `},{
                question: "8. Do I need internet access?",
                answer: `
                <p>
                  You will need internet access to enter the promotion.
                </p>
                <p>
                  You also need a valid email address in order to claim a Prize.
                </p>
              `}
              ])
            }
          </div>
          <div className="faqs__section">
            <h2 className="type-h3">Prize details, claiming and redemption</h2>
            {
              renderFaqs(2, [{
                question: "9. What prizes are available?",
                answer: `
                <p>
                  There are 4 Level 1 Prizes up for grabs, each Prize includes 1 x meet with player 
                  (either in-person or online, subject to player availability) and 1 x £/€ 5k holiday voucher. 
                  There are also thousands of other Prizes including: shirts signed by our epic player lineup  
                  (Harry Kane, Leah Williamson, Virgil van Dijk, Neymar Jr.), personalised video messages and high
                  street shopping vouchers (Lifestyle vouchers for UK, AllGifts.IE vouchers for ROI).
                  Subject to availability. For a full Prize breakdown head to section 10 of our T&Cs
                  <a href="/terms-and-conditions#clause-10">here</a>.
                </p>
              `},{
                question: "10. I didn't win a Winning Moments Prize on the website but it says I've been entered into another draw, what is this?",
                answer: `
                <p>
                  Our Level 1 prizes are won via a draw from all valid entries at the end of the
                  promotion - the draw you are entered into relates to the player specific Prize draw
                  you chose during the entry process. Each valid campaign entry equates to 1x entry
                  into the relevant Prize draw. You can find more about this on the T&Cs page <a href="/terms-and-conditions">here</a>.
                </p>
              `},{
                question: "11. How will I know if I have won?",
                answer: `
                <p>
                  If you have provisionally won a signed shirt, personalised video message or a
                  shopping voucher you will be notified instantly on screen upon completion of the
                  online entry form. You'll then be contacted via email to confirm your identity and
                  eligibility as well as your Prize claim details, so keep an eye out and don't forget to
                  check your junk folder! If you are a Level 1 Prize winner, we'll contact you at the end
                  of the promotion in line with our <a href="/terms-and-conditions">T&Cs</a>.
                </p>
              `},{
                question: "12. I didn't receive my email for my Signed Shirt, Personalised Video Message or Shopping Voucher",
                answer: `
                <p>
                  If you do not receive your email following the online provisional win notification
                  (remember to check your junk email inbox), please contact <a href="mailto:cadburyhomeaway@promowinners.com" target="_blank" rel="noopener noreferrer">cadburyhomeaway@promowinners.com</a>
                </p>
              `}
              ,{
                question: "13. Is there a deadline for me to claim my Signed Shirt, Personalised Video Message or Shopping Voucher?",
                answer: `
                <p>
                  Yes, you must complete the online claim form in the email within 14 days of
                  initial contact. Please see sections 11 and 12 in the <a href="/terms-and-conditions#clause-11">T&Cs</a> for further
                  details.
                </p>
              `}
              ,{
                question: "14. If I choose a player for the Level 1 Prize, do I win a signed shirt/personalised video message (tier 2 Prize) from the same player if I'm successful?",
                answer: `
                <p>
                  No, the second tier Prizes are randomly allocated and you will win a signed shirt/personalised video from any of the 4 players. They are all incredible football heroes - lucky you!
                </p>
              `}
              ])
            }
          </div>
          <div className="faqs__section">
            <h2 className="type-h3">On pack codes and entry mechanics</h2>
            {
              renderFaqs(3, [{
                question: "15. What codes do I need to enter to participate?",
                answer: `
                <p>
                  If you live in England, Scotland, Wales or Northern Ireland you must enter both the
                  barcode and batch code as part of your entry, which can be found on the back of
                  your promotional Cadbury product. The barcode number is the 13-digit code
                  directly under the barcode which is scanned in store. The batch code is a 10-digit
                  alpha-numerical code found within the box on the back of your pack located
                  directly under the “Best Before” date.
                </p>
                <p>
                  Entrants in ROI do not need a code to enter.
                </p>
              `},{
                question: "16. I can't read my code",
                answer: `
                <p>
                  In some circumstances the batch code can be illegible or difficult to read. If you
                  are having difficulties reading your code please contact our customer service
                  team on <a href="tel:+44800818181">0800 818 181</a> or at <a href="https://cadbury.co.uk/contact/" target="_blank" rel="noopener noreferrer">www.cadbury.co.uk/contact/</a> for UK residents or <a href="https://cadbury.ie/contact/" target="_blank" rel="noopener noreferrer">www.cadbury.ie/contact/</a> for residents of Ireland.
                </p>
              `},{
                question: "17. My code isn't working, am I entering the right code?",
                answer: `
                <p>
                  It can be confusing as bars often feature many codes on the back of the pack for
                  production reasons. Please double check you are entering the correct code within
                  the required form field.
                </p>

                <p>
                  The barcode number is the 13-digit code directly under the barcode which is
                  scanned in store. The batch code is a 10-digit alpha-numerical code found within
                  the box on the back of your pack located directly under the “Best Before” date.
                </p>
              `},{
                question: "18. I've already entered my code; can I enter it again for another chance to win?",
                answer: `
                <p>
                  Each entry needs to be accompanied by a new purchase, as the same code
                  cannot be entered multiple times. There is however a maximum of 20 entries 
                  per person during the entire Promotion Period.
                </p>
              `},{
                question: "19. My code has been declined online and I get an error message, what can I do?",
                answer: `
                <p><strong>If the error message is 'This code is not recognised'</strong></p>
                <p>
                  Please check that you're trying to enter the right code. Bars often feature many
                  codes on the back of the pack for production reasons. The barcode number is the
                  13-digit code directly under the barcode which is scanned in store. The batch
                  code is a 10-digit alpha-numerical code found within the box on the back of your
                  pack located directly under the “Best Before” date.
                </p>

                <p><strong>If the error message is 'This code has already been used'</strong></p>
                <p>
                  Remember, users are limited to one entry per person per email address per day.
                  Only your first entry each day will be counted. Please note that there is a maximum
                  of 20 entries per person during the entire Promotion Period.
              </p>
              `}
              ,{
                question: "20. I entered the wrong email address. What should I do?",
                answer: `
                <p>
                  If you have entered an incorrect email address please contact our customer
                  service team on <a href="tel:+44800818181">0800 818 181</a> or at either <a href="https://cadbury.co.uk/contact/" target="_blank" rel="noopener noreferrer">www.cadbury.co.uk/contact/</a> for UK residents or <a href="https://cadbury.ie/contact/" target="_blank" rel="noopener noreferrer">www.cadbury.ie/contact/</a> for residents of Ireland.
                </p>
              `}
              ,{
                question: "21. Why is the URL showing as homeandaway.cadbury.co.uk when I came through from the URL homeandaway.cadbury.ie?",
                answer: `
                <p>
                  The main Cadbury hub is the UK site, so all other country URLs will route to the
                  co.uk site but, rest assured, everything else will stay the same.
                </p>
              `}
              ])
            }
          </div>
         
        </div>}  
      </div>
    </section>
  </>;
}