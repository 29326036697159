import React, {useEffect} from 'react';
import classList from 'react-classlist-helper';
import './PrizeBlockDouble.scss';

const PrizeBlockDouble = (props) => {

  const mainClassMap = {
      "prize-block-double": true,
  };


  return (
    <article className={classList(mainClassMap)}>

      <div className="prize-block-double__info">
        
            <div className="prize-block-double__image">
            <picture>
              { props.imageLarge &&
                <source srcSet={props.imageLarge} media="(min-width: 800px)" />
              }
              <img src={props.image} alt={props.alt || props.title} />
            </picture>
          </div>
          <h2 className="prize-block-double__title type-h1"  dangerouslySetInnerHTML={{__html: props.title}}></h2>
          <div className='prize-block-double__info-copy' dangerouslySetInnerHTML={{__html: props.description}}>
          </div>
     
      </div>
      <div className="prize-block-double__info">
      
          <div className="prize-block-double__image">
            <picture>
              { props.imageLarge &&
                <source srcSet={props.image2Large} media="(min-width: 800px)" />
              }
              <img src={props.image2} alt={props.alt2 || props.title2} />
            </picture>
          </div>
          <h3 className="prize-block-double__title type-h1"  dangerouslySetInnerHTML={{__html: props.title2}}></h3>
          <div className='prize-block-double__info-copy' dangerouslySetInnerHTML={{__html: props.description2}}>
          </div>
     
      </div>
    </article>
  )
}

export default PrizeBlockDouble;
