import React from 'react';
import classList from 'react-classlist-helper';
import { Parallax } from "react-parallax";

//player images
import keyvis_players_dt from 'assets/images/campaign/keyvis/keyvis_players_dt.png';
import keyvis_players_dt_2x from 'assets/images/campaign/keyvis/keyvis_players_dt@2x.png';
import keyvis_players_tab from 'assets/images/campaign/keyvis/keyvis_players_tab.png';
import keyvis_players_tab_2x from 'assets/images/campaign/keyvis/keyvis_players_tab@2x.png';
import keyvis_players_mob from 'assets/images/campaign/keyvis/keyvis_players_mob.png';
import keyvis_players_mob_2x from 'assets/images/campaign/keyvis/keyvis_players_mob@2x.png';


//text images
import keyvis_text_dt from 'assets/images/campaign/keyvis/keyvis_text_dt.png';
import keyvis_text_dt_2x from 'assets/images/campaign/keyvis/keyvis_text_dt@2x.png';
import keyvis_text_tab from 'assets/images/campaign/keyvis/keyvis_text_tab.png';
import keyvis_text_tab_2x from 'assets/images/campaign/keyvis/keyvis_text_tab@2x.png';
import keyvis_text_mob from 'assets/images/campaign/keyvis/keyvis_text_mob.png';
import keyvis_text_mob_2x from 'assets/images/campaign/keyvis/keyvis_text_mob@2x.png';

import FadeIn from 'components/FadeIn/FadeIn';

import './KeyVisual.scss';


export default () => {

  const classes = {
    "keyvis": true
  }

  let parallaxMargin = 22;
  let parallaxPercentage = 80;
  let offsetParPercentage = 90;
  let strength = 100;
  var isDesk = window.matchMedia("only screen and (min-width: 640px)").matches;

  if (isDesk) {
    parallaxMargin = 0;
    strength = 100;
    parallaxPercentage = 140;
    offsetParPercentage = 140;
  }

  const offsetPercentage = (percentage) => {
    return percentage - offsetParPercentage;
  }

  return (
    <div className={classList(classes)}>
      <Parallax
        strength={strength}
        renderLayer={percentage => (
          <div className='keyvis-wrapper'>
            <div className="keyvis__bg"
              style={{
                transform: `translate3d(0, ${offsetPercentage((percentage * parallaxPercentage) + parallaxMargin)}px, 0)`,
              }}
            ></div>

            <FadeIn className='keyvis__headline'>
              <picture>
                <source type="image/png" srcSet={keyvis_text_dt_2x + " 2x, " + keyvis_text_dt + " 1x"} media="(min-width:992px)" />
                <source type="image/png" srcSet={keyvis_text_tab_2x + " 2x, " + keyvis_text_tab + " 1x"} media="(min-width:640px)" />
                <img src={keyvis_text_mob} srcSet={keyvis_text_mob_2x + " 2x, " + keyvis_text_mob + " 1x"} alt="Home and Away Wins" />
              </picture>
            </FadeIn>

            <picture>
              <source type="image/png" srcSet={keyvis_players_dt_2x + " 2x, " + keyvis_players_dt + " 1x"} media="(min-width:992px)" />
              <source type="image/png" srcSet={keyvis_players_tab_2x + " 2x, " + keyvis_players_tab + " 1x"} media="(min-width:640px)" />
              <img src={keyvis_players_mob} srcSet={keyvis_players_mob_2x + " 2x, " + keyvis_players_mob + " 1x"} alt="Football players: Virgil van Dijk, Leah Williamson, Harry Kane and Neymar Jr."
                className='keyvis__players' />
            </picture>
          </div>

        )}
      />

    </div>

  )
}