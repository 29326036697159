import React from 'react';
import classList from 'react-classlist-helper';

import BadgeBorder from 'assets/images/campaign/badges/badge-border-purple.png';
import BadgeBorder2x from 'assets/images/campaign/badges/badge-border-purple@2x.png';

import './Prize.scss';

const Prize = (props) => {
  const classMap = {
    "prize-badge": true,
    "prize-badge--selectable": props.selectable,
  };

  const imageClassMap = {
    "prize-badge__image": true,
    "prize-badge__image--contain": props.containImage,
  };

  const onChange = (e, id) => {
    
    if (props.onChange) {
      props.onChange(props.prize);
    }

    /*if(!e.target.checked){
      const video = document.querySelector('#prize_badge-_video_'+id);
      video.currentTime = 0;
      video.play();
    }*/

  }

  const handlePlayVideo = (e, id) => {
    if(!e.target.checked){
      const video = document.querySelector('#prize_badge-_video_'+id);
      video.currentTime = 0.01;
      video.play();
    }
  }

  const handlePauseVideo = (e, id) => {
    
    if(!e.target.checked){
      const video = document.querySelector('#prize_badge-_video_'+id);
      video.pause();
      video.currentTime = 0.01;
    }
    
  }

  const handlePlayVideoOnFocus = (e, id) => {
    if(window.innerWidth < 1025){
      const video = document.querySelector('#prize_badge-_video_'+id);
      video.currentTime = 0.01;
      video.play();
    }
  }

  const handlePauseVideoOnBlur = (e, id) => {
   // console.log("window.innerWidth: " + window.innerWidth);

    //console.log("e.target : " + e.target.checked);
    if(window.innerWidth < 1025){
      const video = document.querySelector('#prize_badge-_video_'+id);
      video.pause();
      video.currentTime = 0.01;
    }
  }


  return (
    <div className={classList(classMap)} key={"prize_" + props.prize.id}>
      { props.model &&
        <input 
          className="prize-badge__radio" 
          type="radio" 
          id={"prize_badge_" + props.prize.id} 
          name={props.model} 
          value={props.prize.id} 
          checked={props.selected} 
          onChange={(e) => onChange(e, props.prize.id)} 
          tabIndex={props.tab}
          
          onMouseOver={(e) => handlePlayVideo(e, props.prize.id)}
          onMouseOut={(e) =>handlePauseVideo(e, props.prize.id)}

          onFocus={(e) =>handlePlayVideoOnFocus(e, props.prize.id)}
          //onBlur ={(e) =>handlePauseVideoOnBlur(e, props.prize.id)}
          
        />
      }
      
      <div htmlFor={"prize_badge_" + props.prize.id}  className={classList(imageClassMap)}>
      
        <div className='prize-badge__player-wrapper'>
          
          {
            props.prize.videoSelected &&
            <div className="player prize-badge__player-selected">
              <video 
                preload="auto"
                autoPlay="autoplay" 
                id={"prize_badge-_video_"+props.prize.id}
                muted 
                loop
                playsInline 
                /*onCanPlay={onCanPlay}
                onTimeUpdate={onTimeUpdate}
                onEnded={onEnded}*/
                width="100%"
                height="100%"
              >
                <source src={props.prize.videoSelected} type="video/mp4" />
              </video>
            </div>
            
          }
          <picture>    
            <source media="(min-width: 769px)" srcSet={props.prize.image2x + " 2x, " + props.prize.image + " 1x"} type="image/png" />
            <img src={props.prize.image} srcSet={props.prize.image2x + " 2x, " + props.prize.image + " 1x"} alt={props.prize.title} className="prize-badge__player" />  
          </picture> 
        </div>

        <img src={BadgeBorder} srcSet={BadgeBorder2x +" 2x, "+ BadgeBorder + " 1x"} className="prize-badge__border" alt=""  /> 

      </div>
      <h3 className="prize-badge__title"><span>{props.prize.title}</span></h3>
      {
        (props.prize.description !== null && props.prize.description !== "") ??
        <div className="prize-badge__info" dangerouslySetInnerHTML={{__html: props.prize.description}}></div>
      }
      
    </div>
  )
}

export default Prize;
