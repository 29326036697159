import React from 'react';
import { Redirect } from 'react-router-dom';
import ForgottenPassword from './ForgottenPassword';

import useSettingsContext from 'contexts/useSettingsContext';

export default () => {

  
  const settings = useSettingsContext();
  let compState = settings.competitionStatus.openState;
  let competitionIsOpen = compState === "Open";
  let isIreland = settings.country === "ROI";

/*
  //M&W fix.
  const search = window.location.search;
  const params = new URLSearchParams(search);
  var country = params.get('country')

  if(country != null && country.length > 0)
  {
    country = country.toLocaleLowerCase();

    switch(country)
    {
      case"ie":
      case"roi":

        settings.country = "ROI";
        settings.setCountry("ROI");

        break;

      case"ni":

        settings.country = "NI";
        settings.setCountry("NI");

        break;
      case"gb":
      case"uk":
      default:
  
        settings.country = "GB";
        settings.setCountry("GB"); 
        break;
    }
  }*/
  

  if (!settings.isLoggedIn && isIreland && competitionIsOpen) {
    return <ForgottenPassword />
  } else {
    return <Redirect to='/' />
  }
}