import ValidationErrorCollection from './ValidationErrorCollection';

const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const ALPHA_REGEX = /^[A-Za-z]+$/;
const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,50}$/;

function validate(command, settings) {
  let messages = new ValidationErrorCollection();

  if (!command) {
    throw new Error('command cannot be null'); 
  }

  //console.log(command)

  required('password', 'Please enter your new password');
  required('confirmNewPassword', 'Please check and confirm your new password');
  required('recaptcha2', 'Please confirm you are not a robot');

  if (command.password && !PASSWORD_REGEX.test(command.password)) {
    messages.addError('password', 'Your password must be at least 8 characters long and include at least one upper case letter, one lower case letter and one digit');
  }

  if (command.password && command.password.toLowerCase() !== command.confirmNewPassword.toLowerCase()) {
    messages.addError('confirmNewPassword', 'Please check and confirm your new passwords match');
  }

  return messages;
  
  function required(prop, error) {
    if (!command[prop]) {
      messages.addError(prop, error);
    }
  }
}

export default {
  validate
};
