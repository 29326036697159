import React, { useEffect } from 'react';

export default () => {
  useEffect(() => {
    if (window && window.picturefill) {
      window.picturefill();
      // Transitions may not have initiated at this point
      setTimeout(window.picturefill, 50);
    }
  });
};
