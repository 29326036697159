import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import analyticsService from 'services/analyticsService';
import useEffectOnce from 'hooks/useEffectOnce';
import useSettingsContext from 'contexts/useSettingsContext';
import accountService from 'services/accountService';
import accountLoginValidator from 'validators/accountLoginValidator';
import SettingsContext from 'contexts/SettingsContext';
import Button from 'components/Button/Button';
import TextField from 'components/Forms/TextField';
import Link from 'components/Link/Link';
import HiddenField from 'components/Forms/HiddenField';
import ValidationMessage from 'components/Validation/ValidationMessage';
import ValidationSummary from 'components/Validation/ValidationSummary';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';
import ReCaptcha from 'components/Forms/ReCaptcha';
import Modal from 'components/Modal/Modal';
import AlertIcon from 'assets/images/alert.png';
import Form, { createOnChangeHandler } from './Form';

import './Form.scss';

export default (props) => {
  let { token } = useParams();
  let history = useHistory();
  let settingsContext = useSettingsContext();
  let [isSubmitting, setIsSubmitting] = useState(false);
  let [settings, setSettings] = React.useContext(SettingsContext);
  let [email, setEmail] = useState('');
  let [password, setPassword] = useState('');
  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  useEffectOnce(() => {
    window.scrollTo(0, 0);
  });

  const createCommand = () => {
    return {
      email,
      password,
      country: settings.country
    };
  }

  const onFormSuccess = async (result) => {
    // Save email to DataLayer - GTM uses this to pass to Lytics (Only if the user has accepted targeting cookies)
     analyticsService.logEvent("forms", "Forms", {
      action: "EmailCapture",
       email
     });
     analyticsService.logEvent("forms", "Forms", {
       action: "LoginSuccess"
     });

    // Update csfr token and flag as logged in
    setSettings({ 
      ...settings, 
      isLoggedIn: true, 
      csrfToken: result.csrfToken,
      country: result.country,
      account: result.account
    });

    setIsSubmitting(true);

    // Wait a tick so state has synced
    setTimeout(() => props.onStateChange && props.onStateChange({ isLoggedIn: true, error: false }), 0);
  }

  const onFormFail = (error) => {
    analyticsService.logEvent("forms", "Forms", {
      action: "LoginFail",
      label: error
    });

    props.onStateChange && props.onStateChange({ isLoggedIn: false, error: true });
  }

  const onDismissModal = () => {
    setValidation(prevState => ({ ...prevState, acknowledged: true }));
  }

  return <>
    <Form 
      id="login"
      submitTitle="Log in"
      isSubmitting={isSubmitting}
      onSuccess={onFormSuccess}
      onFail={onFormFail}
      command={{
        model: createCommand,
        service: accountService.login
      }}
      validation={{
        validator: accountLoginValidator,
        onValidation: setValidation
      }} 
    >
    
    { validation.errors.hasErrors && validation.acknowledged &&
      <ValidationSummary 
        errors={validation.errors}
        handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
      />
    }

    <fieldset className="form__fieldset">
      <TextField 
        label="* Email address"
        id="email"
        type="email"
        onChange={createOnChangeHandler(validation, 'email', setEmail)}
        value={email}
        hasErrors={!!validation.errors.email}
      />
      <ValidationMessage errors={validation.errors.email} />
    </fieldset>

    <fieldset className="form__fieldset">
      <TextField 
        label="* Password"
        id="password"
        type="password"
        onChange={createOnChangeHandler(validation, 'password', setPassword)}
        value={password}
        hasErrors={!!validation.errors.password}
      />
      <ValidationMessage errors={validation.errors.password} />
    </fieldset>

    <ReCaptcha captchaSettings={settingsContext.captchaSettings} />

    { validation.errors.hasErrors && !validation.acknowledged &&
      <Modal 
        panel 
        hideClose
        icon={AlertIcon}
        alt="Warning sign"
        iconTooltip="Alert"
        onDismiss={onDismissModal}
        onConfirm={onDismissModal}
        title="Oops">
        <ValidationSummary 
            modal
            defaultMessage="Please check you have entered all fields correctly."
            errors={validation.errors}
            handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
        />
        { validation.deadend ? (
            <Link title="Back to homepage" lozenge to="/">Back to homepage</Link>
        ):(
            <Button title="Try again" onClick={onDismissModal} />
        )}
      </Modal>
    }
    </Form>
  </>;
}