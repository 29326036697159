import React from 'react';
import classList from 'react-classlist-helper';
import './Accordion.scss';

export default (props) => {
  const classMap = {
    "accordion accordion--secondary": true,
    "accordion--open": props.open,
    [props.className]: !!props.className,
  };

  return (
    <div id={props.id} className={classList(classMap)}>
      <button className="accordion__toggle" type="button" tabIndex="0" onClick={props.onClick}>
        {props.title}
        <div className="accordion__icon">
          <span></span>
          <span></span>
        </div>
      </button>
      <div className="accordion__body" dangerouslySetInnerHTML={{ __html: props.answer }}>
      </div>
    </div>
  );
}