import React from 'react';
import classList from 'react-classlist-helper';
import './ModalBackground.scss';

export default (props) => {
  function onBackgroundClick(event) {
    if (props.onDismiss && event.target === event.currentTarget) {
      props.onDismiss();
    }
  }

  const classMap = {
    "modal-background": true,
    "modal-background--show": props.show
  };

  return (
    <div className={classList(classMap)} onClick={onBackgroundClick}>
      {props.children}
    </div>
  );
}