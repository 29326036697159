import React, { useState, useEffect } from 'react';
import Modal from 'components/Modal/Modal';
import CountrySelectForm from 'containers/Forms/CountrySelectForm';

export default (props) => {
  const onCountrySelected = (country) => {
    props.onCountrySelected(country);
    document.body.classList.remove("overflow-hidden-mediumdown");
  }



  if (props.show) {
    return (
      <Modal panel className="modal--country">
        <CountrySelectForm 
          onSubmit={onCountrySelected}
          focus={props.show}
        />
      </Modal>
    )
  } else {
    return null;
  }
}