import React, { useState } from 'react';
import { Helmet } from "react-helmet";

import analyticsService from 'services/analyticsService';
import useEffectOnce from 'hooks/useEffectOnce';
import useSettingsContext from 'contexts/useSettingsContext';
import accountService from 'services/accountService';
import accountForgottenPasswordValidator from 'validators/accountForgottenPasswordValidator';
import SettingsContext from 'contexts/SettingsContext';

import Button from 'components/Button/Button';
import TextField from 'components/Forms/TextField';
import ValidationMessage from 'components/Validation/ValidationMessage';
import ValidationSummary from 'components/Validation/ValidationSummary';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';
import ReCaptcha from 'components/Forms/ReCaptcha';
import Modal from 'components/Modal/Modal';
import AlertIcon from 'assets/images/alert.png';
import Form, { createOnChangeHandler } from './Form';

import './Form.scss';

export default () => {
  let settingsContext = useSettingsContext();
  let [settings, setSettings] = React.useContext(SettingsContext);

  let [complete, setComplete] = useState(false);
  let [email, setEmail] = useState('');
  let [recaptcha2, setRecaptcha2] = useState(null);
  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  useEffectOnce(() => {
    window.scrollTo(0, 0);
  });

  const createCommand = () => {
    return {
      email,
      country: settings.country,
      recaptcha2
    };
  }

  const onFormSuccess = async (result) => {
    analyticsService.logEvent("forms", "Forms", {
      action: "ForgottenPasswordSuccess"
    });

    setComplete(true);
  }

  const onFormFail = (error) => {
    analyticsService.logEvent("forms", "Forms", {
      action: "ForgottenPasswordFail",
      label: error
    });
  }

  const onDismissModal = () => {
    setValidation(prevState => ({ ...prevState, acknowledged: true }));
    /* This has to be here to bypass hideClose */
    document.body.classList.remove("overflow-hidden-mediumdown"); 
  }

  useEffectOnce(() => {
    window.setTimeout(function(){
      try 
      {
        let $grecaptchav2 = document.querySelector('.g-recaptcha-v2');
        if ($grecaptchav2) {
          
            window.grecaptcha.ready(() => {
                window.grecaptcha.render($grecaptchav2, {
              'sitekey' : '6LeSFWApAAAAAHf7x_em5yVBSpdiqq6ZVW6Nrvwk',
              'callback': handleReCaptchaV2,
              'expired-callback': handleReCaptchaV2Expired
                });
          });
        }
      }catch{}
    }, 1000);
  });

  var handleReCaptchaV2 = (val) => {
    if(val !== null && val !== ""){
      createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2, val)
    }
  }

  var handleReCaptchaV2Expired = () => {
    setRecaptcha2(null);
  }


  /* RENDER */
  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Forgotten password | Cadbury FC - Home & Away Wins</title>
    </Helmet>
    <section className="layout-general layout-wrapper">
        <div className="layout-container layout-container--slim layout-container--center">
          

          { !complete ? (
            <>
              <div className="form__intro">
                <h1 className="type-h1">Forgotten Password</h1>
                <p >Enter you email address and a password reset email will follow shortly.</p>
              </div>

              <Form 
                id="triggerPasswordReset"
                submitTitle="Send"
                formClass="form form--slim"
                onSuccess={onFormSuccess}
                onFail={onFormFail}
                command={{
                  model: createCommand,
                  service: accountService.forgottenPassword
                }}
                validation={{
                  validator: accountForgottenPasswordValidator,
                  onValidation: setValidation
                }}
                submitAnalytics={{evnt: "form", category: "ForgottenPasswordForm", action: "EmailCapture", label: email}}
              >
                
                { validation.errors.hasErrors && validation.acknowledged &&
                  <ValidationSummary 
                    errors={validation.errors}
                    handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
                  />
                }

                <fieldset className="form__fieldset">
                  <TextField 
                    label="Email address"
                    id="email"
                    type="email"
                    onChange={createOnChangeHandler(validation, 'email', setEmail)}
                    value={email}
                    hasErrors={!!validation.errors.email}
                  />
                  <ValidationMessage errors={validation.errors.email} />
                </fieldset>

                <fieldset className="form__fieldset">
                  <input type="hidden" id="Country" name="country" value={settings.country} />
                </fieldset>

                <fieldset className="form__fieldset">
                  
                  <ReCaptcha captchaSettings={settingsContext.captchaSettings} />    
                  <div class="g-recaptcha-v2" data-sitekey="6LeSFWApAAAAAHf7x_em5yVBSpdiqq6ZVW6Nrvwk"
                  data-load={createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2)}></div>
                  <ValidationMessage errors={validation.errors.recaptcha2} /> 

                </fieldset>

                { validation.errors.hasErrors && !validation.acknowledged &&
                  <Modal 
                    panel 
                    hideClose
                    panelBackground
                    icon={AlertIcon}
                    iconTooltip="Alert"
                    onDismiss={onDismissModal}
                    onConfirm={onDismissModal}
                    title="Oops">
                    <ValidationSummary 
                      modal
                      defaultMessage="Please check you have entered all fields correctly."
                      errors={validation.errors}
                      handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
                    />
                    <Button title="Try again" onClick={onDismissModal} />
                  </Modal>
                }
              </Form>
            </>
          ):(
            <div className="form__intro">
              <h1 className="type-h1">Forgotten Password</h1>
              <p>Your reset password email should be on its way now.</p>
              <p><Button title="Back to homepage" href="/" /></p>
              
            </div>         
            
          )}

        </div>
    </section>
  </>;
}