import React from 'react';
import { Helmet } from "react-helmet";
import { useRef } from 'react';
import useEffectOnce from 'hooks/useEffectOnce';
import { useHistory } from 'react-router-dom';
import './Policies.scss';

export default (props) => {
  const history = useHistory();
  const appendix = useRef(null);
  const clause6 = useRef(null);
  const clause13 = useRef(null);

  useEffectOnce(() => {
    if (history.location.hash.indexOf("products-list") > -1) {
      const section = document.querySelector('#products-list');
      if (section !== null) { section.scrollIntoView({ behavior: 'smooth', block: 'start' }); }
    }

    if (history.location.hash.indexOf("prize-breakdown") > -1) {
      const section = document.querySelector('#prize-breakdown');
      if (section !== null) { section.scrollIntoView({ behavior: 'smooth', block: 'start' }); }
    }

    if (history.location.hash.indexOf("clause-10") > -1) {
      const section = document.querySelector('#clause-10');
      if(section !== null) { section.scrollIntoView({ behavior: 'smooth', block: 'start' }); }
    }

    if (history.location.hash.indexOf("clause-11") > -1) {
      const section = document.querySelector('#clause-11');
      if(section !== null) { section.scrollIntoView({ behavior: 'smooth', block: 'start' }); }
    }
  });

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: 'smooth'
    })
  }

  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Terms & Conditions | Cadbury FC - Home & Away Wins</title>
    </Helmet>
    <section className="layout-general layout-wrapper layout-general--full">
      <div className="layout-container layout-container--slim layout-container--center">
        <div className='policies'>
          <h1 className="type-h1">T&C<span>s</span></h1>

          <div className='policies__content'>
            <br />
            <ol>
              <li>
                <p>This Promotion is open to residents of the United Kingdom and Republic of Ireland aged 18 or over, excluding employees of the Promoter, its agents and anyone professionally associated with this Promotion.</p>
              </li>

              <li>
                <p>Purchase is necessary for UK residents ONLY. <strong>Please retain an itemised receipt that states the time and date of purchase prior to entry, but within the Promotion Period, as this may be required for validation and in order for the winner to receive their Prize.</strong> Internet access and a valid email address are required.</p>
              </li>

              <li>
                <p><strong>Promotion Period:</strong></p>
                <ol type="a">
                  <li><p><strong>Prize Draw and Winning Moments:</strong> Enter between 10:00 BST on the 22 April 2024 and 16:59 BST on the 19 July 2024 inclusive.</p></li>
                  <li><p><strong>Wrap Up Draw:</strong> Enter between 17:00 BST on 19 July 2024 and 16:59 GMT on 19 January 2025 inclusive.</p> </li>
                </ol>
              </li>

              <li>
                <p><strong>To Enter:</strong></p>
                <ol type='a'>
                  <li>
                    <p><strong>Level 1 Prize Draw</strong></p>
                    <ol type='i'>
                      <li><p><strong>UK:</strong> Purchase a Promotional Product, see <button type="button" className="button button--text-link" onClick={() => scrollToSection(clause6)}>clause 6</button> for a list of Promotional Products. Visit <a href="https://homeandaway.cadbury.co.uk" target="_blank" rel="noopener noreferrer">homeandaway.cadbury.co.uk</a> and complete the online form, providing the barcode and batch code, along with your details (full name, email address, your postcode, the retailer you purchased from and your club preference) and select your player preference from a line-up of Participating Players, to be entered into the relevant Prize Draw.</p></li>
                      <li><p><strong>ROI:</strong> Visit <a href="https://homeandaway.cadbury.ie" target="_blank" rel="noopener noreferrer">homeandaway.cadbury.ie</a> and complete the online form with your details (full name, email address, your full address including eir code and club preference) and select your player preference from a line-up of Participating Players, to be entered into the relevant Prize Draw.</p></li>
                    </ol>
                  </li>
                  <li>
                    <p><strong>Level 2/3 Winning Moments:</strong> All valid entries from UK and ROI are also submitted into a winning moments promotion.</p>
                  </li>
                  <li>
                    <p><strong>Wrap-Up Draw:</strong></p>
                    <ol type='i'>
                      <li><p><strong>UK:</strong> Purchase a Promotional Product within the Wrap-Up Draw Promotional Period, visit <a href="https://homeandaway.cadbury.co.uk" target="_blank" rel="noopener noreferrer">homeandaway.cadbury.co.uk</a>, and select your region. Complete the online form providing the bar/batch code along with your details (full name, email address and postcode).</p></li>
                      <li><p><strong>ROI:</strong> Visit <a href="https://homeandaway.cadbury.ie" target="_blank" rel="noopener noreferrer">homeandaway.cadbury.ie</a> within the Wrap-Up Draw Promotional Period and select ROI region. Complete the online form with your details (full name, email address and eir code). </p></li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>
                <p><strong>Participating Players:</strong></p>
                <ol type="a">
                  <li><p>Virgil van Dijk</p></li>
                  <li><p>Harry Kane</p></li>
                  <li><p>Leah Williamson</p></li>
                  <li><p>Neymar Jr.</p></li>
                </ol>
              </li>

              <li ref={clause6}>
                <p><strong>Promotional Products:</strong></p>
                <ol type="a">
                  <li><p>Please see <button type="button" className="button button--text-link" onClick={() => scrollToSection(appendix)}>Appendix 1</button> for details.</p></li>
                </ol>
              </li>

              <li>
                <p>Maximum of 1 entry is permitted per person per day during the Promotion Period. Maximum of 20 entries per person during the entire Promotion Period. For entrants in the UK, one itemised receipt per entry must state the time and date of purchase, which must pre-date the entry date and time, but be within the Promotion Period. No household may win more than 1 x Level 1 Prize, 2 x Level 2 Prizes and 3 x Level 3 Prizes. </p>
              </li>

              <li>
                <p>A person is defined by name and email address and the combination needs to be unique to a single participant for a prize claim to be valid. Postal addresses used to claim may be checked and verified via Royal Mail PAF for eligibility. Winners are provisional at point of win/claim and subject to prize claim verification to qualify win. </p>
              </li>

              <li>
                <p><strong>The Prizes:</strong></p>
                <ol type="a">
                  <li><p><strong>Level 1 Prize Draw:</strong> There are 4 Prizes available to be won, 1 for each Participating Player. One winner per Participating Player will win 1 x player experience to meet the Participating Player they selected upon entry, for themselves and up to 3 guests for up to 60 minutes either in person or online depending on availability and 1 x £5,000 holiday voucher. There will be a total of 4 winners. If the experience is in-person standard class travel for winner and 3 guests and 1 x overnight stay in a minimum 4* accommodation for winner and 3 guests will be included. If the experience is online, no compensation or substitution will be provided for any difference in prize value.</p></li>
                  <li>
                    <p><strong>Level 2 Winning Moments:</strong> There will be a total of 60 winners. Prizes will be randomly allocated. The Participating Player selected on entry may not correspond to the Level 2 Prize you receive if you are a winner.</p>

                    <ol type="i">
                      <li><p>40 x signed shirts by a player (maximum of 10x shirts per participating player, subject to availability and to be dictated by the Promoter).</p></li>
                      <li><p>20 x personalised videos from a player to winner (minimum 10 seconds and will be dictated and scripted by the promoter), 5 per Participating Player.</p></li>
                    </ol>
                  </li>
                  <li>
                    <p><strong>Level 3 Winning Moments:</strong> There are 2001 Prizes available to be won. Prizes will be randomly allocated.</p>
                    <ol type="i">
                      <li><p>500 x £/€10 Lifestyle vouchers (UK) or AllGifts.IE gift card (ROI).</p></li>
                      <li><p>1501 x £/€5 Lifestyle vouchers (UK) or AllGifts.IE gift card (ROI).</p></li>
                    </ol>
                  </li>
                  <li>
                    <p><strong>Wrap Up Draw:</strong></p>
                    <ol type="i">
                      <li><p>1x £/€250 Lifestyle voucher (UK)/AllGifts.IE gift card (ROI).</p></li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li id="clause-10">
                <p><strong>Further Entry and Prize Details and Conditions:</strong></p>
                <ol type="a">
                  <li><p>The Promoter may request that all winners participate in reasonable promotional activity (such as publicity and photography) surrounding the winning of the prize, free of charge, and they consent to the Promoter using their name and images in promotional material. The winners are under no obligation to participate and may decline this request. Participation is at the winner's discretion and is not a condition of prize acceptance.</p></li>
                  <li><p>By entering this promotion, the winner grants the Promoter the absolute and irrevocable right and permission to use, publish, publicly display, perform, transmit, exhibit and reproduce their name, statements, video, voice, recordings, interviews, photographs or other visual or audible representations or likeness, in whole or in part, individually or in conjunction with other material, including without limitation, text, photographs or images in any medium (whether now known or hereafter invented) including, but not limited to the internet and other electronic and social media and for any and all purposes, publicity, promotion, packaging and trade throughout the world without restriction as to manner, frequency or duration of use or any other purpose whatsoever in connection with this promotion or in connection with the marketing of the products of the Promoter, or otherwise. The winner further agrees that all materials produced pursuant to the rights set out above shall and will remain the property of the Promoter.</p></li>
                  <li>
                    <p>Level 1 Prizes:</p>
                    <ol type="i">
                      <li><p>The experience will take place either online or in-person. The date and location are unconfirmed and will be determined during winner communications. The date and location are subject to change and are dependent on the player's availability and schedule.</p></li>
                      <li><p>For the Neymar Jr. player experience, the experience will be online, the exact dates are unconfirmed.</p></li>
                      <li><p>Any reference to "Home" does not allude to the players' homes.</p></li>
                      <li><p>The Prize will not take place at a player's home.</p></li>
                      <li><p>The player experiences must be taken before 31 December 2024. The experiences are subject to the chosen Participating Players' availability. The Promoter will endeavour to provide as much visibility as possible for the Prize date, although timings may only be confirmed (and are liable to move) days in advance, subject to the Participating Players' availability. Neither the Promoter nor the Participating Player will be liable in any way for any cancellation or postponement of the Prize.</p></li>
                      <li><p>If the experience is in-person, the prize will include standard class travel to and from the experience venue (if in person) and a minimum 4* hotel stay for 1 night (in 2x twin or 2x double rooms) for winner and 3 guests as well as food and drink at the experience.</p></li>
                      <li><p>If the experience is online, no compensation or substitution will be provided for any difference in prize value.</p></li>
                      <li><p>Across all Participating Players involved in the Promotion, the experience will take place on a date pre-determined by the Participating Player and Promoter (with winners unable to change). Winners may need to attend at short notice anytime from July 2024 onwards. Experiences will also be pre-determined by the player and Promoter.</p></li>
                      <li><p>It is the responsibility of the Winner and their guests to satisfy any Covid-19 testing or vaccination requirements applicable.</p></li>
                      <li><p>In order to facilitate the smooth-running of the Prize, there will be a minimum of 2 representatives of the Promoter and the Participating Player at the player experience. In the event that, in the opinion of the Promoter, the winner or their guests behave and/or speak in an inappropriate manner or ask questions that have not been pre-approved, the representatives of the Promoter or Participating Player will issue an initial warning. If the behaviour and/or speech continues, the representatives of the Promoter or Participating Player will terminate the meet & greet and no alternative Prize, or any other form of compensation will be provided. The decision of the Promoter is final, and no correspondence will be entered into.      </p></li>
                      <li><p>In the event that the player experience takes place virtually, the winner will receive a link to access an online platform through which the player experience will be held.</p></li>
                      <li><p>The Level 1 Prize Winners will be subject to a name check against any Club, venue or sporting Banning Orders as well as the National Police Database for those who may be subject to a court banning order. </p></li>
                      <li><p>If any of the guests are under the age of 18, then they should be accompanied by a responsible adult and with their guardian's consent.</p></li>
                      <li><p>Except mobile phones used for personal and private use only, you may not bring into (or use within) any Club's stadium any equipment that is capable of recording or transmitting any audio, visual or audio-visual material or any information or data in relation to a match or any aspect of it.</p></li>
                      <li><p>For the avoidance of doubt, the Prize does not include, souvenirs, gratuities, car parking charges, excess baggage, additional excursions and attractions or any other costs of a personal nature (including spending money), or any costs incurred due to Covid-19, that are not explicitly set out in these Terms and Conditions and neither the Promoter nor any provider of any part of the Prize will be responsible for any such costs. For the avoidance of doubt, dinner at the hotel will not be included. </p></li>
                      <li><p>Redemption of the Level 1 Prize is subject to Government guidelines and lockdown measures which can be found at: <a href="https://gov.uk/coronavirus" target="_blank" rel="noopener noreferrer">https://www.gov.uk/coronavirus</a>.</p></li>
                      <li><p>If Government guidelines restrict redemption of the Prize in its current form, the Promoter will work with the winner to discuss moving the prize to a later date or issuing an alternative prize.</p></li>
                      <li><p>At all times, the Promoter will have the discretion as to how to implement the Prize fulfilment.</p></li>
                      <li><p>Once dates, tickets, or names are booked, they cannot be altered or changed except at the Promoter's sole discretion. </p></li>
                      <li><p>If a Prize Draw Winner wins an online player experience, no compensation or substitution will be provided for difference in prize value.</p></li>
                      <li><p>If a Prize Winner wins an in-person experience in the UK, city ground transportation may be provided by Promoter in lieu of air transportation and no compensation or substitution will be provided for difference in prize value.  </p></li>
                      <li><p>The dates available for the experiences are not guaranteed and are subject to C19 / club restrictions around access and dates.</p></li>
                      <li><p>The Level 1 Prize will be arranged by email/phone between the Promoter's agency and the individual winners. The Promoter's agency will make all reasonable efforts to provide the winner with details of the prize at the earliest possible opportunity. The Promoter will have final discretion on all elements and aspects of how the prize is organised. Once the prize details, including dates, locations are confirmed, they cannot be amended or altered by the winner.</p></li>
                      <li><p>The winner is responsible for the behaviour of themselves and their guests whilst taking the Prize. The Promoter reserves the right in its absolute discretion to exclude the winner and/or their guests from participation in any aspect of the Prize if any party fails to comply with the directions of the Promoter or any companies associated with the Prize or if the winner and/or their guests act in a manner that is dangerous to themselves or to the public or is, in the opinion of the Promoter, anti-social in any manner or which causes a disturbance or nuisance to others. Failure to comply with these Terms and Conditions may result in refused entry, or the ticket holders being required to leave the event/venue.</p></li>
                      <li><p>The holiday voucher further details and terms and conditions will be made available to the Winner once the provider has been confirmed. </p></li>
                      <li>
                        <p>The travel retailers included in the holiday voucher are: </p>
                        <ol type="1">
                          <li><p>Airbnb</p></li>
                          <li><p>Airline Gift UK</p></li>
                          <li><p>Alfresco Holidays </p></li>
                          <li><p>British Airways</p></li>
                          <li><p>Club Med</p></li>
                          <li><p>Crystals Ski Holidays </p></li>
                          <li><p>Disneyland Paris </p></li>
                          <li><p>Eurostar</p></li>
                          <li><p>Expedia </p></li>
                          <li><p>EasyJet Holidays</p></li>
                          <li><p>Emirates</p></li>
                          <li><p>Flight gift card</p></li>
                          <li><p>G-Adventures</p></li>
                          <li><p>Global Hotel Card</p></li>
                          <li><p>Hotel Gift</p></li>
                          <li><p>Hertz (Car Rental)</p></li>
                          <li><p>Hard Rock Hotels </p></li>
                          <li><p>Hotels.com</p></li>
                          <li><p>Inspire Travel</p></li>
                          <li><p>James Villa's</p></li>
                          <li><p>Jet2Holidays by Inspire Travel</p></li>
                          <li><p>Kuoni Travel</p></li>
                          <li><p>Lastminute.com - flight/travel gift card</p></li>
                          <li><p>RentACar</p></li>
                          <li><p>Ryanair </p></li>
                          <li><p>Sandals Travel </p></li>
                          <li><p>Qatar Airways </p></li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p>Level 2 Prizes:</p>
                    <ol type="i">
                      <li><p>Which player signed shirt is received by the winner will be dictated by the promoter subject to availability and will be sent to an address within UK / ROI. Size of shirt to be dictated by Promoter. </p></li>
                      <li><p>Delivery of shirt could take up to 6 months. Please contact the Promoter via the email in <button type="button" className="button button--text-link" onClick={() => scrollToSection(clause13)}>clause 13</button> if you have not received your shirt within this time. </p></li>
                      <li><p>The personalised video messages from a player to the winner will be scripted by the promoter and shared with winners via email.</p></li>
                      <li><p>The personalised video message is not to be shared publicly, i.e. via any social media platforms.</p></li>
                      <li><p>Receipt of a video could take up to 6 months. Please contact the Promoter via the email in <button type="button" className="button button--text-link" onClick={() => scrollToSection(clause13)}>clause 13</button> if you have not received your video within this time. </p></li>
                    </ol>
                  </li>

                  <li>
                    <p>Level 3 Prizes & Wrap Up Prize Details: </p>
                    <ol type="i">
                      <li><p>Lifestyle voucher terms and conditions can be found <a href="https://lifestylegiftcards.co.uk/terms" target="_blank" rel="noopener noreferrer">here</a>. The voucher will expire 18 months from date of Prize Acceptance.</p></li>
                      <li><p>AllGifts.IE terms and conditions can be found <a href="https://allgifts.ie/terms_and_conditions.aspx" target="_blank" rel="noopener noreferrer">here</a>. The voucher will expire 5 years from date of Prize Acceptance.</p></li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li id="clause-11">
                <p>Winner Selection:</p>
                <ol type="a">
                  <li><p><strong>Level 1 Prize Draw:</strong> 1 winner per Participating Player Draw will be randomly selected from all valid entries received for each Participating Player during the Promotion Period. For your total confidence, the Prize Draws will be conducted by PromoVeritas, the independent promotional verification service, within 5 working days of the end of the Prize Draw and Winning Moments Promotion Period.</p></li>
                  <li><p><strong>Level 2 Winning Moments:</strong> During the Promotion Period, 60 "winning moments" (days, hours, minutes, seconds), a minimum of 4 winning moments per week have been randomly selected by an independent verification service. The first valid entry on or after that winning moment will be awarded the Prize. If no entry is received between one winning moment to the next, the winning moment will be rolled over. All 60 Prizes are available to be won.</p></li>
                  <li><p><strong>Level 3 Winning Moments:</strong> During the Promotion Period, 2001 "winning moments" (days, hours, minutes, seconds), a minimum of 153 winning moments per week have been randomly selected by an independent verification service. The first valid entry on or after that winning moment will be awarded the Prize. If no entry is received between one winning moment to the next, the winning moment will be rolled over. All 2001 Prizes are available to be won.</p></li>
                  <li><p><strong>Wrap Up Draw:</strong> 1 provisional winner will be randomly selected from all valid entries received during the Wrap Up Draw Period. For your total confidence, the Prize Draw will be conducted by PromoVeritas, the independent promotional verification service, within 5 working days of the end of the Wrap Up Draw Period.</p></li>
                </ol>
              </li>

              <li>
                <p><strong>Winner Notification:</strong></p>
                <ol type="a">
                  <li><p><strong>Level 1 Prize Draw:</strong> Provisional winners will be contacted via the email address provided on entry within 10 working days of Winner Selection and will be asked to provide evidence of identity and eligibility. Reasonable efforts will be made to contact the winners. If a winner does not respond to the initial contact and provide valid evidence of their identification for verification within 5 days, we reserve the right to disqualify that entrant and award the Prize to a reserve selected in the same manner. Reserve winners may have less time to respond.</p></li>
                  <li><p><strong>Level 2 and 3 Winning Moments:</strong> The provisional winning entrants will be instantly notified on screen and asked to follow the instructions within 14 days to claim their Prize. Winners will also receive a confirmation email to their email address used upon entry, confirming their Prize and will be required to follow the instructions within 14 days of initial contact. If provisional winners do not follow the instructions either on screen or in the confirmation email within 14 days of initial contact, the Promoter reserves the right to disqualify those provisional winners and return the Prizes to the promotion Prize pool.</p></li>
                  <li><p><strong>Wrap Up Draw:</strong> The provisional winner will be contacted via the email address provided on entry within 10 days of Wrap Up Draw Winner Selection and will be asked to provide evidence of identity and eligibility. Reasonable efforts will be made to contact the winner. If the winner does not respond to the initial contact within 14 days, the Promoter reserve the right to disqualify that entrant and award the Prize to a reserve selected in the same manner. Reserve winners may have less time to respond.</p></li>
                </ol>
              </li>

              <li ref={clause13}>
                <p><strong>Prize Acceptance: </strong>Once eligibility of a Prize is confirmed and the Prize is accepted, winners will be notified within 28 days of acceptance of the Prize to arrange fulfilment of the Prize. In the unlikely event that a winner does not receive their notification within 28 days, the winners must inform the Promoter by emailing <a href="mailto:cadburyhomeaway@promowinners.com" target="_target" rel="noopener noreferrer">cadburyhomeaway@promowinners.com</a>. If a winner does not do so, the Promoter reserves the right to not reissue the Prize or limit its value at its sole discretion.</p>
              </li>

              <li>
                <p>Provided no objection is received, the surname and county of the Level 1 Prize winners will be made available to anyone who requests them by writing to <strong>JS13984 Home and Away Wins, PO Box 704, Pinner, HA5 9PF</strong>, within 3 months of the closing date. In the event they win, winners may request their surname and county are not published or request for the amount of information being published to be reduced during the Winner Notification process. Without prejudice, the Promoter will provide this information to the Advertising Standards Authority on reasonable request.</p>
              </li>

              <li>
                <p>The Promoter may request that the winners participate in reasonable publicity arising from the Promotion. Participation is at the winner's discretion and is not a condition of Prize Acceptance.</p>
              </li>

              <li>
                <p>The Promotion is administered and run by the Promoter and is not in any way sponsored, endorsed or administered by, or associated with Harry Kane, Virgil van Dijk, Leah Williamson, or Neymar Jr. By entering the Promotion, you acknowledge that Harry Kane, Virgil van Dijk, Leah Williamson, or Neymar Jr. shall not have any liability to you in connection with this Promotion.</p>
              </li>

              <li>
                <p>The Promoter seeks to run a fair and secure promotion and prevent abuse and cheating. If you enter in a way that is not consistent with these Terms & Conditions, your entries (and any of your associated aliases) will be disqualified. Any Prize awarded may be void and recoverable, and the Promoter reserves the right to bar you from entering future promotions for a period of at least six months. For this reason, the Promoter reserves the right at any point to:</p>
                <ol type="a">
                  <li><p>Verify the eligibility of entrants and/or provisional winners by requesting such information it consider reasonably necessary for this purpose. Entry or a Prize may be withheld until verification is completed.</p></li>
                  <li><p>Disqualify entries that are not made directly by the individual entering the Promotion. </p></li>
                  <li><p>Disqualify entries made using anonymous email services such as, but not limited to, GuerillaMail, Dispostable or Mailinator.</p></li>
                  <li><p>Disqualify bulk entries from individuals, trade, consumer groups or third parties, incomplete entries and entries submitted by macros or other automated means.</p></li>
                  <li><p>Disqualify entries beyond the maximum allowed, or those using techniques such as 'script', 'brute force', multiple SIM cards for text entries, masking identity by manipulating IP addresses, using aliases or identities other than their own or any other means.</p></li>
                  <li><p>Disqualify entrants who tamper with the entry process.</p></li>
                  <li><p>Disqualify entries which, in some other way, do not meet the requirements of these Terms & Conditions. </p></li>
                  <li><p>Cancel, modify or suspend the Promotion should it not be capable of running as planned for reasons including but not limited to tampering, unauthorised intervention, fraud, dishonesty, technical failures, or any other causes beyond the control of the Promoter which corrupt or affect the administration, security, fairness, integrity or proper conduct of this Promotion.</p></li>
                </ol>
              </li>

              <li>
                <p>The Prizes are not transferable or exchangeable and cannot be redeemed for monetary value or for any other form of compensation. If for any reason a Prize or any element of a Prize is not available, the Promoter reserves the right, at its sole discretion to substitute that Prize or element of a Prize for another of equal or greater value.</p>
              </li>

              <li>
                <p>No responsibility can be taken for entries which are lost, delayed, corrupted, damaged, misdirected or incomplete or which cannot be delivered for any technical, delivery or other reason. Proof of sending will not be accepted as proof of receipt. The Promoter does not guarantee continuous or secure access to <a href="https://homeandaway.cadbury.co.uk" target="_blank" rel="noopener noreferrer">homeandaway.cadbury.co.uk</a> or <a href="https://homeandaway.cadbury.ie" target="_blank" rel="noopener noreferrer">homeandaway.cadbury.ie</a>.</p>
              </li>

              <li>
                <p>The Promoter and its associated agencies and companies are not liable for any loss (including, without limitation, indirect, special or consequential loss or loss of profits), expense or damage which is suffered or sustained (whether or not arising from any person's negligence) in connection with this Promotion or accepting or using the/a Prize(s), except for any liability which cannot be excluded by law. Nothing will exclude the Promotor's liability for death or personal injury as a result of its negligence.</p>
              </li>

              <li>
                <p>The Promoter will not be liable for any delay or failure to comply with its obligations for reasons beyond its reasonable control arising from but not limited to Acts of God, global or regional epidemic or pandemic, adverse weather conditions, fire, industrial dispute, war, terrorist activity, hostilities, political unrest, riots, civil commotion, plague or other natural calamities, or any other circumstances.</p>
              </li>

              <li>
                <p>The Promoter shall comply with all applicable requirements of the Data Protection Act 2018, the General Data Protection Regulation 2016 and any successor legislation or other applicable law. The personal information entrants provide will be used by the Promoter for the purpose of conducting this promotion and in accordance with the Promoters' Data Privacy Notice which can be found at <a href="https://cadbury.co.uk/privacy" target="_blank" rel="noopener noreferrer">www.cadbury.co.uk/privacy</a>. The Promoter may disclose entrants' personal information to its contractors and agents to assist in conducting this Promotion (including any relevant promotional activity) or communicating with entrants.</p>
              </li>

              <li>
                <p>If any part of these Terms & Conditions are legally ruled to be invalid, illegal or unenforceable, the remainder will not be affected and will continue in full force and effect.</p>
              </li>

              <li>
                <p>By entering, you will be deemed to have agreed to be bound by these Terms & Conditions.</p>
              </li>

              <li>
                <p>If there is a discrepancy between these Terms & Conditions and those in any promotional material, these Terms & Conditions will prevail. </p>
              </li>

              <li>
                <p>These Terms & Conditions are governed by English law and their interpretation and application will be subject to the exclusive jurisdiction of the courts of England and Wales.</p>
              </li>
            </ol>
            <p><strong>Promoter:</strong></p>
            <p>UK: Mondelez Europe Services GmbH - UK Branch whose address is at Cadbury House, Sanderson Road, Uxbridge, UB8 1DH.</p>
            <p>ROI: Mondelez Europe Services GmbH - Ireland Branch whose address is at Malahide Road, Coolock, Dublin 5 </p>
            <p>(together the "Promoter").</p>
          </div>
        </div>

        <div className='policies'>
          <div id="products-list" ref={appendix} className="type-h3 type-h3--gold">Appendix 1:</div>

          <ol type="1" className='reduced-list'>
            <li>	Caramilk Buttons 105g	</li>
            <li>	Caramilk Buttons price marked pack 90g	</li>
            <li>	Caramilk Buttons 90g	</li>
            <li>	Cadbury Darkmilk Buttons 105g	</li>
            <li>	Cadbury White Buttons 110g	</li>
            <li>	Cadbury Dairy Milk Orange Buttons 110g	</li>
            <li>	Cadbury Bournville Dark Buttons 110g	</li>
            <li>	Cadbury Picnic Bites 110g	</li>
            <li>	Cadbury Crunchie Rocks 110g	</li>
            <li>	Cadbury Dinky Deckers 120g	</li>
            <li>	Cadbury Dairy Milk Buttons 119g	</li>
            <li>	Cadbury Curly Wurly Squirlies 110g	</li>
            <li>	Cadbury Dairy Milk Caramel Nibbles 120g	</li>
            <li>	Cadbury Oreo Bites 110g	</li>
            <li>	Cadbury Twirl Bites 110g	</li>
            <li>	Cadbury Fudge Bites 110g	</li>
            <li>	Cadbury Bitsa Wispa 110g	</li>
            <li>	Cadbury Dairy Milk Buttons 240g	</li>
            <li>	Cadbury Dairy Milk Caramel Nibbles 240g	</li>
            <li>	Cadbury Bitsa Wispa 222g	</li>
            <li>	Cadbury Dairy Milk Buttons 330g	</li>
            <li>	Cadbury Twirl price marked pack 95g	</li>
            <li>	Cadbury Darkmilk Buttons price marked pack 90g	</li>
            <li>	Cadbury Curly Wurly Squirlies price marked pack 95g	</li>
            <li>	Cadbury Dairy Milk Buttons price marked pack 95g	</li>
            <li>	Cadbury Bitsa Wispa price marked pack 95g	</li>
            <li>	Cadbury Dairy Milk Caramel Nibbles price marked pack 95g	</li>
            <li>	Cadbury Oreo Bites price marked pack 95g	</li>
            <li>	Cadbury Bournville Dark Buttons price marked pack 95g	</li>
            <li>	Cadbury White Buttons price marked pack 95g	</li>
            <li>	Cadbury Dairy Milk Orange Buttons price marked pack 95g	</li>
            <li>	Cadbury Darkmilk Buttons 90g	</li>
            <li>	Cadbury White Buttons 95g	</li>
            <li>	Cadbury Oreo Bites 95g	</li>
            <li>	Cadbury Twirl Bites 95g	</li>
            <li>	Cadbury Dairy Milk Caramel Nibbles 95g	</li>
            <li>	Cadbury Bourmville Buttons 95g	</li>
            <li>	Cadbury Dairy Milk Buttons 95g	</li>
            <li>	Cadbury Bitsa Wispa 95g	</li>
            <li>	Cadbury Curly Wurly Squirlies 95g	</li>
            <li>	Cadbury Dairy Milk Orange Buttons 95g	</li>
            <li>	Cadbury Dairy Milk Buttons price marked pack 95g	</li>
            <li>	Cadbury Dairy Milk Nibbls price marked pack 95g	</li>
            <li>	Cadbury Twirl Bites price marked pack 95g	</li>
            <li>	Cadbury Dairy Milk Orange Buttons price marked pack 95g	</li>
            <li>	Cadbury Bitsa Wispa price marked pack 95g	</li>
            <li>	Cadbury Caramilk Buttons price marked pack 90g	</li>
            <li>	Cadbury White Chocolate Buttons price marked pack 95g	</li>
            <li>	Cadbury Oreo Bites price marked pack 95g	</li>
            <li>	Cadbury Dairy Milk Mint Buttons price marked pack 95g	</li>
            <li>	Cadbury Curly Wurly Squirlies price marked pack 95g	</li>
            <li>	Cadbury Darkmilk Buttons price marked pack 90g	</li>
            <li>	Cadbury Dairy Milk Mega Buttons 192g	</li>
            <li>	Cadbury Dairy Milk Twisted Buttons 105g	</li>
            <li>	Cadbury Dairy Milk Mint Buttons 110g	</li>
            <li>	Cadbury Dairy Milk Mint Buttons 95g PMP	</li>
            <li>	Cadbury Dairy Milk Buttons 85g PMP	</li>
            <li>	Cadbury Dairy Milk Buttons 85g	</li>
            <li>	Cadbury Dairy Milk Caramel Nibbles 85g PMP	</li>
            <li>	Cadbury Dairy Milk Caramel Nibbles 85g	</li>
            <li>	Cadbury Twirl Bites 85g PMP	</li>
            <li>	Cadbury Twirl Bites 85g	</li>
            <li>	Cadbury Bitsa Wispa price marked pack 85g	</li>
            <li>	Cadbury Bitsa Wispa  85g	</li>
            <li>	Cadbury Oreo Bites price marked pack 85g	</li>
            <li>	Cadbury Dairy Milk Orange Buttons price marked pack 85g	</li>
            <li>	Cadbury Dairy Milk Orange Buttons 85g	</li>
            <li>	Cadbury White Chocolate Buttons price marked pack 85g	</li>
            <li>	Cadbury White Chocolate Buttons 85g	</li>
            <li>	Caramilk Buttons price marked pack 85g	</li>
            <li>	Caramilk Buttons 85g	</li>
            <li>	Cadbury Curly Wurly Squirlies price marked pack 85g	</li>
            <li>	Cadbury White Mega Buttons 192g	</li>
            <li>	Cadbury Home and Away Wins Caramel Nibbles 120g	</li>
            <li>	Cadbury Home and Away Wins Giant Buttons 119g	</li>
            <li>	Cadbury Home and Away Wins Caramel Block 180g	</li>
            <li>	Cadbury Home and Away Wins Wholenut Block 180g	</li>
            <li>	Cadbury Home and Away Wins Fruit & Nut Block 180g	</li>
            <li>	Cadbury Home and Away Wins Crunchie Multipack 10CA	</li>
            <li>	Cadbury Home and Away Wins Twirl Multipack 12CA	</li>
            <li>	Cadbury Home and Away Wins Wispa Multipack 11CA	</li>
            <li>	Cadbury Home and Away Wins Cadbury Dairy Milk Multipack 15CA	</li>
            <li>	Wine Gum Jucies 130g	</li>
            <li>	Win Gums 165g	</li>
            <li>	Sports Mix 165g	</li>
            <li>	Sour Patch Kids 130g	</li>
            <li>	Chocolate Brunch Bar 160g	</li>
            <li>	Raisin Brunch Bar 160g	</li>
            <li>	Bournville Brunch Bar 160g	</li>
            <li>	Peanut Brunch Bar 160g	</li>
            <li>	Cadbury 200 years Wispa 4 pack	</li>
            <li>	Cadbury 200 years Crunchie 4 pack	</li>
            <li>	Cadbury 200 years Twirl 5 pack	</li>
            <li>	Cadbury 200 years Cadbury Dairy Milk 4 pack	</li>
            <li>	Cadbury 200 years Wispa 4 pack	</li>
            <li>	Cadbury 200 years Crunchie 4 pack	</li>
            <li>	Cadbury 200 years Caramel Nibbles 120g	</li>
            <li>	Cadbury 200 years Buttons 119g	</li>
            <li>	Cadbury 200 years Buttons 85g	</li>
            <li>	Cadbury 200 years Caramel Nibbles 85g	</li>
            <li>	Cadbury 200 years Caramel Bar 180g	</li>
            <li>	Cadbury 200 years Dairy Milk Bar 180g	</li>
            <li>	Cadbury 200 years Wholenut Bar 180g	</li>
            <li>	Cadbury 200 years Fruit & Nut Bar 180g	</li>
            <li>	Cadbury Flake 4pk 80g	</li>
            <li>	Cadbury Flake 4pk 102g	</li>
            <li>	Cadbury Wispa 4pk 94.8g	</li>
            <li>	Cadbury Wispa Gold 4pk 134g	</li>
            <li>	Cadbury Twirl 5pk 107.5g	</li>
            <li>	Cadbury Crunchie 4pk 104.4g	</li>
            <li>	Cadbury Picnic 4pk 128g	</li>
            <li>	Cadbury Double Decker 4pk 149.2g	</li>
            <li>	Cadbury Dairy Milk 4pk 108.8g	</li>
            <li>	Cadbury Boost 4pk 126g	</li>
            <li>	Cadbury Caramilk 4pk 122g	</li>
            <li>	Cadbury Wispa 4pk 111.6g	</li>
            <li>	Cadbury Twirl 5pk 136g	</li>
            <li>	Cadbury Crunchie 4pk 128g	</li>
            <li>	Cadbury Picnic 4pk 152g	</li>
            <li>	Cadbury Double Decker 4pk 174.8g	</li>
            <li>	Cadbury Boost 4pk 148g	</li>
            <li>	Cadbury Caramel 4pk 148g	</li>
            <li>	Cadbury Dairy Milk 4pk 134g	</li>
            <li>	Cadbury Wispa Gold 4pk 153.2g	</li>
            <li>	Cadbury Wispa 9pk 213.3g	</li>
            <li>	Cadbury Twirl 10pk 215g	</li>
            <li>	Cadbury Dairy Milk 9pk 244.8g	</li>
            <li>	Cadbury Double Decker 9pk 335.7g	</li>
            <li>	Cadbury Boost Multipack 4pk 148g	</li>
            <li>	Cadbury Twirl Multipack 5pk 107.5g PMP	</li>
            <li>	Cadbury Picnic Multipack 4pk 128g PMP	</li>
            <li>	Cadbury Double Decker Multipack 4pk 149.2g PMP	</li>
            <li>	Cadbury Boost Multipack 4pk 126g PMP	</li>
            <li>	Cadbury Wispa Multipack 4pk 94.8g PMP	</li>
            <li>	Cadbury Twirl 5pk Price Marked Pack 107.5g	</li>
            <li>	Cadbury Boost 4pk Price Marked Pack 126g	</li>
            <li>	Cadbury Wispa 4pk Price Marked Pack 94.8g	</li>
            <li>	White Fingers 114g	</li>
            <li>	Milk Fingers PMP 114g	</li>
            <li>	Milk Fingers 114g	</li>
            <li>	Mint Fingers 114g	</li>
            <li>	Tripple Choc Fingers 110g	</li>
            <li>	Tripple Choc Fingers PMP 110g	</li>
            <li>	Bournville Fingers 114g	</li>
            <li>	Orange Fingers 114g	</li>
            <li>	Milk Fingers 189g	</li>
            <li>	Milk Fingers 115.8g	</li>
            <li>	Salted Caramel Fingers 114g	</li>
            <li>	Milk Fingers 114g promo	</li>
            <li>	Mini Fingers 125g	</li>
            <li>	Nibbly Fingers 125g	</li>
            <li>	Cadbury Brunch Choc Chip PMP 160g	</li>
            <li>	Cadbury Brunch Choc Chip 160g	</li>
            <li>	Cadbury Brunch Raisin 160g	</li>
            <li>	Cadbury Brunch Peanut 160g	</li>
            <li>	Cadbury Brunch Bournville 160g	</li>
            <li>	Snack Shortcake 120g	</li>
            <li>	Snack Sandwich 136g	</li>
            <li>	Timeout Std 121g	</li>
            <li>	Timeout Std 141g	</li>
            <li>	Timeout PMP 121g	</li>
            <li>	Timeout Orange 121g	</li>
            <li>	Timeout Orange 141g	</li>
            <li>	Nuttier Peanut Almond 120g	</li>
            <li>	Nuttier Almond Cramberry 120g	</li>
            <li>	Trebor Softmint Peppermint 44.9g (PMP)	</li>
            <li>	Trebor Softmint Spearmint 44.9g (PMP)	</li>
            <li>	Trebor Extra Strong Peppermint 41.3g (PMP)	</li>
            <li>	Trebor Extra Strong Peppermint 41.3g	</li>
            <li>	Trebor Softmint Peppermint 44.9g	</li>
            <li>	Trebor Softmin Spearmint 44.9g	</li>
            <li>	Trebor Extra Strong Peppermint 4 Pack 165.2g	</li>
            <li>	Trebor Softmint Peppermint 4 Pack 179.6g	</li>
            <li>	Trebor Softmint Spearmint 4 Pack 179.6g	</li>
            <li>	Trebor Extra Strong Sugar Free Minis 12.6g	</li>
            <li>	Trebor Extra Strong Sugar Free Minis 12.6g	</li>
            <li>	MB Wine gums 165g	</li>
            <li>	MB jelly babies 165g	</li>
            <li>	MB Sports mix 165g	</li>
            <li>	MB Liqourice 165g	</li>
            <li>	MB Wine gums 165g	</li>
            <li>	MB jelly babies 165g	</li>
            <li>	MB jelly babies 130g	</li>
            <li>	MB liqourice 130g	</li>
            <li>	MB sports mix 130g	</li>
            <li>	MB wine gums 130g	</li>
            <li>	MB mini gems 130g	</li>
            <li>	MB fizzy fish 130g	</li>
            <li>	MB wine gums 130g PMP	</li>
            <li>	MB jelly babies 130g PMP	</li>
            <li>	MB sports mix 130g PMP	</li>
            <li>	MB liqourice 130g PMP	</li>
            <li>	MB mini gems 130g PMP	</li>
            <li>	MB fizzy fish 130g PMP	</li>
            <li>	MB wine gums bag 350g	</li>
            <li>	MB jelly babies bag 350g	</li>
            <li>	MB Liqourice bag 350g	</li>
            <li>	MB sports mix juicies 130g	</li>
            <li>	MB wine gums juicies 130g	</li>
            <li>	MB sherbet lemon 192g	</li>
            <li>	MB murray mint 193g	</li>
            <li>	MB mint fav 192g	</li>
            <li>	TNCC jelly snakes 130g	</li>
            <li>	TNCC sour squirms 130g	</li>
            <li>	TNCC dino mix 130g	</li>
            <li>	SPK Original 130g	</li>
            <li>	SPK fruit mix 130g	</li>
            <li>	SPK cola 130g	</li>
            <li>	SPK watermelon 130g	</li>
            <li>	SPK Original 120g PMP	</li>
            <li>	MB cherry roll singles	</li>
            <li>	MB wine gum singles	</li>
            <li>	MB wine gums singles PMP	</li>
            <li>	MB wine gums carton 350g	</li>
            <li>	MB liqourice carton 350g	</li>
            <li>	MB jelly babies carton 350g	</li>
            <li>	MB sports mix carton 350g	</li>
            <li>	SPK original carton 350g	</li>
            <li>	Cadbury Twirl 43g	</li>
            <li>	Cadbury Wipsa 36g	</li>
            <li>	Cadbury Boost 48.5g	</li>
            <li>	Cadbury Crunchie 40g	</li>
            <li>	Cadbury Double Decker 54.5g	</li>
            <li>	Cadbury Wispa Gold 48g	</li>
            <li>	Cadbury Twirl 43g (price marked pack)	</li>
            <li>	Cadbury Wipsa 36g (price marked pack)	</li>
            <li>	Cadbury Boost 48.5g (price marked pack)	</li>
            <li>	Cadbury Crunchie 40g (price marked pack)	</li>
            <li>	Cadbury Double Decker 54.5g (price marked pack)	</li>
            <li>	Cadbury Wispa Gold 48g (price marked pack)	</li>
            <li>	Cadbury Starbar 49g (price marked pack)	</li>
            <li>	Cadbury Dairy Milk 45g (price marked pack)	</li>
            <li>	Cadbury Dairy Milk Marvellous Creations 47g (price marked pack)	</li>
            <li>	Cadbury Picnic 48.4g (price marked pack)	</li>
            <li>	Cadbury Dairy Milk Wholenut 45g	</li>
            <li>	Cadbury Dairy Milk Caramel 45g	</li>
            <li>	Cadbury Dairy Milk 45g	</li>
            <li>	Cadbury Dairy Milk Fruit & Nut 45g	</li>
            <li>	Cadbury Marvellous Creations 47g	</li>
            <li>	Cadbury Giant Buttons 40g	</li>
            <li>	Cadbury Starbar 49g	</li>
            <li>	Cadbury Picnic 48g	</li>
            <li>	Cadbury Flake 32g	</li>
            <li>	Cadbury Twirl Orange 43g	</li>
            <li>	Cadbury Twirl Xtra 54g	</li>
            <li>	Cadbury Boost Duo 63g	</li>
            <li>	Cadbury Double Decker Duo 74.6g	</li>
            <li>	Cadbury Dairy Milk Duo 54.4g	</li>
            <li>	Cadbury Wispa Duo 47.4g	</li>
            <li>	Cadbury Wispa Gold Duo 67g	</li>
            <li>	Cadbury Dairy Milk Caramel 45g (price marked pack)	</li>
            <li>	Starbar Duo 74g	</li>
            <li>	Crunchie 9 pack	</li>
            <li>	Cadbury Crunchie 4pk 104.4g Price marked	</li>
            <li>	Cadbury Bournville 180g	</li>
            <li>	Cadbury Bournville Orange 180g	</li>
            <li>	Cadbury White 180g	</li>
            <li>	Cadbury Bournville 100g	</li>
            <li>	Cadbury Bournville Orange 100g	</li>
            <li>	Cadbury Bournville Old Jamaica	</li>
            <li>	Cadbury Dark Milk Salted Caramel 85g	</li>
            <li>	Cadbury Dark Milk Cocoa Nibs 85g	</li>
            <li>	Cadbury Oreo White 120g	</li>
            <li>	Cadbur Dairy Milk 30% less sugar	</li>
            <li>	Cadbury Dairy Milk 95g	</li>
            <li>	Cadbury Dairy Milk Oreo Sandwich 96g	</li>
            <li>	Cadbury Dairy Milk 100g	</li>
            <li>	Cadbury Dairy Milk Fruit & Nut 100g	</li>
            <li>	Cadbury Dairy Milk Caramel 120g	</li>
            <li>	Cadbury Dairy Milk Daim 120g	</li>
            <li>	Cadbury Dairy Milk Oreo 120g	</li>
            <li>	Cadbury Dairy Milk Wholenut 120g	</li>
            <li>	Cadbury White 100g	</li>
            <li>	Cadbury White 90g	</li>
            <li>	Cadbury Caramilk 90g	</li>
            <li>	Cadbury Caramilk 80g	</li>
            <li>	Cadbury Dairy Milk 300g	</li>
            <li>	Cadbury Dairy Milk Fruit & Nut 300g	</li>
            <li>	Cadbury Dairy Milk Peanut Caramel 278g	</li>
            <li>	Cadbury Dairy Milk Big Taste Oreo 300g	</li>
            <li>	Cadbury Dairy Milk Big Taste Triolade 300g	</li>
            <li>	Cadbury White 100g	</li>
            <li>	Cadbury Dark Milk 80g £1.25 Price Marked Pack	</li>
            <li>	Cadbuiry Dairy Milk Salted Caramel 120g	</li>
            <li>	Cadbury Bournville Mint 100g	</li>
            <li>	Cadbury Dark Milk 90g	</li>
            <li>	Cadbury Dark Milk 80g	</li>
            <li>	Cadbury Dark Milk Hazelnut 85g	</li>
            <li>	Cadbury Bournville 100g Price Marked Pack	</li>
            <li>	Cadbury Bournville Orange 100g Price Marked Pack	</li>
            <li>	Cadbury Bournville Old Jamaica 100g Price Marked Pack	</li>
            <li>	Cadbury Dairy Milk 95g	</li>
            <li>	Cadbury Dairy Milk Orange 95g Price Marked Pack	</li>
            <li>	Cadbury Dairy Milk Chopped Nut 95g Price Marked Pack	</li>
            <li>	Cadbury Dairy Milk Fruit & Nut 95g	</li>
            <li>	Cadbury Dairy Milk Oreo Sandwich 95g Price Marked Pack	</li>
            <li>	Cadbury Dairy Milk Caramel 120g Price Marked Pack	</li>
            <li>	Cadbury Oreo White 120g Price Marked Pack	</li>
            <li>	Cadbury Dairy Milk Daim 120g Price Marked Pack	</li>
            <li>	Cadbury Dairy Milk Oreo 120g	</li>
            <li>	Cadbury White 90g Price Marked Pack	</li>
            <li>	Cadbury Caramilk 80g Price Marked Pack	</li>
            <li>	Cadbury Dairy Milk Fruit and Nut 95g	</li>
            <li>	Cadbury Dairy Milk Chopped Nut 95g	</li>
            <li>	Cadbury Dairy Milk Orange 95g	</li>
            <li>	Cadbury Caramilk 160g	</li>
            <li>	Cadbury Plant Bar 90g	</li>
            <li>	Cadbury Plant Bar Salted Caramel 90g	</li>
            <li>	Cadbury Darkmilk 150g	</li>
            <li>	Cadbury Dairy Milk Mint 180g	</li>
            <li>	Cadbury Dairy Milk Orange 180g	</li>
            <li>	Cadbury Dairy Milk 180g	</li>
            <li>	Cadbury Dairy Milk Crunchie 180g	</li>
            <li>	Cadbury Dairy Milk Wholenut 180g	</li>
            <li>	Cadbury Dairy Milk Fruit and Nut 180g	</li>
            <li>	Cadbury Dairy Milk Jelly Popping Candy 160g	</li>
            <li>	Cadbury Dairy Milk Caramel 180g	</li>
            <li>	Cadbury Freddo	</li>
            <li>	Cadbury Dairy Milk Freddo Caramel 19.5g	</li>
            <li>	Cadbury Dairy Milk Little Bar 18g	</li>
            <li>	Cadbury Fudge Singles 22g	</li>
            <li>	Cadbury Chomp 21g	</li>
            <li>	Cadbury Curly Wurly 21.8g	</li>
            <li>	Cadbury Freddo Price Mark Packed	</li>
            <li>	Cadbury Caramel Price Mark Packed	</li>
            <li>	Cadbury Chomp 21g Price Mark Packed	</li>
            <li>	Cadbury Fudge 22g Price Mark Packed	</li>
            <li>	Cadbury Freddo 90g 5pk Price Mark Packed	</li>
            <li>	Cadbury Freddo Caramel 97.5g 5pk	</li>
            <li>	Cadbury Buttons 70g 5pk	</li>
            <li>	Cadbury Fudge 110g 5pk	</li>
            <li>	Cadbury Chomp 105g 5pk	</li>
            <li>	Cadbury Curly Wurly 107.5g 5pk	</li>
            <li>	Cadbury Dairy Milk Little Bars 108g 6Pk	</li>
            <li>	Cadbury Freddo 90g Merlin 5pk	</li>
            <li>	Cadbury Freddo Caramel 97.5g Merlin 5pk	</li>
            <li>	Cadbury Fudge 110g 5pk Merlin	</li>
            <li>	Cadbury Curly Wurly 107.5g 5pk	</li>
            <li>	Cadbury Dairy Milk Little Bars 108g 6pk	</li>
            <li>	Cadbury Chocolate Chip Cookie 135g 24CA	</li>
            <li>	Cadbury BV 100G PMP 18CA	</li>
            <li>	Cadbury 120G PMP ORE W 17CA	</li>
            <li>	Cadbury 80G PMP C MILK 26CA	</li>
            <li>	Cadbury Dairy Milk 96G PMP OREO S 15CA	</li>
            <li>	Cadbury 90G WHITE PMP 24CA	</li>
            <li>	Cadbury Dairy Milk 95G PMP 22CA	</li>
            <li>	Cadbury Dairy Milk 95G PMP CN 22CA	</li>
            <li>	Cadbury Dairy Milk 95G PMP F&N 22CA	</li>
            <li>	Cadbury 120G PMP CARAM 16CA	</li>
            <li>	Cadbury Dairy Milk 120G PMP DAIM 18CA	</li>
            <li>	Cadbury Dairy Milk 120G PMP OREO 17CA	</li>
            <li>	Cadbury Dairy Milk 120G PMP OREO 17CA	</li>
            <li>	Cadbury Dairy Milk Oreo 120g Price Marked Pack	</li>
            <li>	Cadbury Dairy Milk Oreo 120g Priced Mark Pack	</li>
            <li>	Cadbury Dairy Milk Fruit & Nut 95g Price Marked Pack	</li>
            <li>	Cadbury Dairy Milk Chopped Nut 95g Price Marked Pack	</li>
            <li>	Cadbury Dairy Milk Caramel 120g Price Marked Pack	</li>
            <li>	Cadbury Dairy Milk 95g Price Marked Pack	</li>
            <li>	Cadbury Dairy Milk Daim 120g Price Marked Pack	</li>
            <li>	Cadbury Dairy Milk Oreo 95g Price Marked Pack	</li>
            <li>	Cadbury White Oreo 120g Price Marked Pack	</li>
            <li>	Cadbury Bournville 100g Price Marked Pack	</li>
            <li>	Cadbury Bournville Old Jamaica 100g Price Marked Pack	</li>
            <li>	Cadbury Bournville Orange 100g Price Marked Pack	</li>
            <li>	Cadbury Dairy Milk Oreo 120g Price Marked Pack	</li>
            <li>	Cadbury White 90g Price Marked Pack	</li>
            <li>	Cadbury Caramilk 80g Price Marked Pack	</li>
            <li>	Cadbury Dairy Milk Caramel 180g (on pack promo)	</li>
            <li>	Cadbury Dairy Milk 180g (on pack promo)	</li>
            <li>	Cadbury Dairy Milk Fruit & Nut 180g (on pack promo)	</li>
            <li>	Cadbury Dairy Milk Wholenut 180g (on pack promo)	</li>
            <li>	Cadbury Roundie 150g	</li>
            <li>	Cadbury Timeout PMP 121g	</li>
            <li>	Cadbury Mini Animals 139g	</li>
            <li>	Cadbury Choco Sandwich 260g	</li>
            <li>	Cadbury Crunchy Melts Oreo 312g	</li>
            <li>	Cadbury Crunchy Melts Choc 312g	</li>
            <li>	Cadbury Crunchy Melts Choc 156g	</li>
            <li>	Cadbury Crunchy Melts Choc 156g	</li>
            <li>	Cadbury Mini Animals 99.5g	</li>
            <li>	Cadbury Mini Fingers 96.5g	</li>
            <li>	Cadbury Dairy Milk 134G MTW 4PK 15CA	</li>
            <li>	Cadbury 43G MTW TWIRL 48CA	</li>
            <li>	Cadbury Dairy Milk 134G MTW 4PK 15CA	</li>
            <li>	Cadbury 43G MTW TWIRL 48CA	</li>
            <li>	Cadbury 36G MTW WISPA 48CA	</li>
            <li>	Cadbury 40G MTW CRUNCHIE 48CA	</li>
            <li>	Cadbury 54.5G MTW DOUBLE DECKER 48CA	</li>
            <li>	Cadbury 48.5G MTW BOOST 48CA	</li>
            <li>	Cadbury Dairy Milk 45G MTW 48CA	</li>
            <li>	Cadbury Dairy Milk 45G MTW CARA 48CA	</li>
            <li>	Cadbury 43G MTW TWIRL ORANGE 48CA	</li>
            <li>	Cadbury Dairy Milk MTW 95G GIANT BUTTONS 10CA	</li>
            <li>	Cadbury Dairy Milk MTW 95G CARAMEL NIBBLES 10CA	</li>
            <li>  Cadbury Chopped Hazelnut Tablet</li>
          </ol>

        </div>
      </div>
    </section>
  </>;
}