import PrizeImage1 from 'assets/images/campaign/badges/profile--vandijk.png';
import PrizeImage2 from 'assets/images/campaign/badges/profile--williamson.png';
import PrizeImage3 from 'assets/images/campaign/badges/profile--kane.png';
import PrizeImage4 from 'assets/images/campaign/badges/profile--neymar.png';

import PrizeImage1x2 from 'assets/images/campaign/badges/profile--vandijk@2x.png';
import PrizeImage2x2 from 'assets/images/campaign/badges/profile--williamson@2x.png';
import PrizeImage3x2 from 'assets/images/campaign/badges/profile--kane@2x.png';
import PrizeImage4x2 from 'assets/images/campaign/badges/profile--neymar@2x.png';

import PrizeImage1Video from 'assets/videos/56023646-003_1g_H&AWins_Player_Selection-568x682_VVD_2024.mp4';
import PrizeImage2Video from 'assets/videos/56023646-003_1g_H&AWins_Player_Selection-568x682_LW_2024.mp4';
import PrizeImage3Video from 'assets/videos/56023646-003_1g_H&AWins_Player_Selection-568x682_HK_2024.mp4';
import PrizeImage4Video from 'assets/videos/56023646-003_1g_H&AWins_Player_Selection-568x682_NJ_2024.mp4';



const prizeList = [
  { 
    "id": "VIR", 
    "image": PrizeImage1, 
    "image2x": PrizeImage1x2, 
    "videoSelected" : PrizeImage1Video, 
    "type": "vertical", 
    "title": "Virgil van Dijk"
  },
  { 
    "id": "WIL", 
    "image": PrizeImage2, 
    "image2x": PrizeImage2x2, 
    "videoSelected" : PrizeImage2Video, 
    "type": "vertical", 
    "title": "Leah Williamson"
  },
  { 
    "id": "KAN", 
    "image": PrizeImage3, 
    "image2x": PrizeImage3x2, 
    "videoSelected" : PrizeImage3Video,
    "type": "vertical", 
    "title": "Harry Kane"
  },
  { 
    "id": "NEY", 
    "image": PrizeImage4, 
    "image2x": PrizeImage4x2, 
    "videoSelected" : PrizeImage4Video, 
    "type": "vertical", 
    "title": "Neymar Jr"
  },
  
  
]

export default prizeList;