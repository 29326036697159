import React, { useRef, useEffect, useState } from 'react';
import classList from 'react-classlist-helper';
import useEffectOnce from 'hooks/useEffectOnce';
import ToolTip from './ToolTip';
import './TextField.scss';

export default (props) => {
  const [value, setValue] = useState(props.value);
  const inputRef = useRef();
  const classMap = {
    "textfield": true,
    "textfield--error": props.hasErrors,
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value])

  useEffectOnce(() => {
    if (props.focus) {
      inputRef.current.focus();
    }
  });

  function onChange(event) {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event.target.value);
    }
  }

  function onBlur(event) {
    event.persist();

    setValue(event.target.value.trim());
    if (props.onChange) {
      props.onChange(event.target.value.trim());
    }    
  }

  return (
    <div className={classList(classMap)}>
      { props.tooltipText ?
        (<div className="textfield--tooltip">
           <label
              className="textfield__label"
              htmlFor={props.id}>
              {props.label}
            </label>
            <ToolTip 
              title={props.label}
              content={props.tooltipText} />
          
        </div>)
        : (
          <label
            className="textfield__label"
            htmlFor={props.id}>
            {props.label}
          </label>
        )
      }
      <div className="textfield__input-wrapper">
        <input
          className="textfield__input"
          type={props.type || "text"} 
          id={props.id} 
          name={props.id}
          value={value || undefined}
          ref={inputRef}
          autoComplete={props.autoComplete}
          placeholder={props.placeholder}
          onChange={onChange}
          onBlur={onBlur}
  	      readOnly={props.readonly}
          disabled={props.readonly}
          pattern={props.pattern}
          inputMode={props.inputMode}
        />
        <span className="textfield__focus"></span>
      </div>
    </div>
  );
}
