import React, { useEffect, useState } from 'react';
import classList from 'react-classlist-helper';
import './FadeIn.scss';


export default (props) => {
  const [isVisible, setVisible] = useState(false);
  const domRef = React.useRef();

  const classMap = {
    "fade-in": true,
    "fade-in--visible" : isVisible,
    [props.className]: !!props.className,
  };

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
  }, []);
 
  return (
    <div
      className={classList(classMap)}
      ref={domRef}
    >
      {props.children}
    </div>
  )
  
}