import React from 'react';
import Loader from 'components/Loader/Loader';
import useSettingsContext from 'contexts/useSettingsContext';
import './SiteLoader.scss';

export default function (props) {
  const settings = useSettingsContext();

  if (!settings.isLoading) {
    return props.children;
  }

  return (
    <div className="site-loader">
      <Loader />
    </div>  
  );
}