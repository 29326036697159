import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import analyticsService from 'services/analyticsService';
import useCompetitionEntryState from 'contexts/useCompetitionEntryContext';
import ActionBar from 'containers/ActionBar/ActionBar';
import Button from 'components/Button/Button';
import prizeList from 'utils/prizes';
import Prize from 'components/Prize/Prize';
import SettingsContext from 'contexts/SettingsContext';
import './PrizeSelection.scss';

export default () => {
  let competitionEntryState = useCompetitionEntryState();
  let history = useHistory();
  let [prize, setPrize] = useState(competitionEntryState.prize);
  let [playerName, setPlayerName] = useState("");

  let [settings, setSettings] = React.useContext(SettingsContext);


  const onContinue = () => {
    if (prize !== null) {
      competitionEntryState.setPrize(prize);
      setSettings({ ...settings, prize: prize.id });

      analyticsService.logEvent("form", "PrizeSelection", {
        action: "SubmitSelectPrize",
        label: prize.id
      });


      history.push("/registration");
    }
  }

  const handleSelectedPrize = (selPrize) => {
    setPrize(selPrize);

    analyticsService.logEvent("form", "PrizeSelection", {
      action: "SelectPrize",
      label: selPrize.id
    });
  }

  /* RENDER */
  return <>
    
    <Helmet>
      <meta charSet="utf-8" />
      <title>Prize Selection | Cadbury FC - Home & Away Wins</title>
    </Helmet>
    <div className="layout">
      
      <section className="layout-general layout-general--full layout-wrapper overflowx-hidden">
        <div className="layout-container layout-container--center layout-container--wide prize-select " >
          <h1 className="type-h1">Pick your Player</h1>
          <p>
            Who would you like to meet, either in person or online?
          </p>
          <form>
            <div className="prize-select__list">
              {
                prizeList.map((p, index) => (
                  <div className='prize-badge-wrapper' key={"prize-wrapper__" + index}>
                    
                    <Prize 
                      id={p.id}
                      tab={index + 1}
                      prize={p} 
                      model="prize"
                      selected={prize && prize.id === p.id} 
                      selectable 
                      onChange={(selected) => handleSelectedPrize(selected)}
                    />
                  </div>
                ))
              }
            </div>
          </form>
        </div>
      </section>
      <ActionBar show={prize !== null}>
        <Button wide title={"Pick " + (prize != null && prize.title != null ? prize.title : "")} onClick={onContinue} />
      </ActionBar>
    </div>
  </>;
}