import ValidationErrorCollection from './ValidationErrorCollection';

const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

function validate(command, settings) {
  let messages = new ValidationErrorCollection();

  if (!command) {
    throw new Error('command cannot be null'); 
  }

  required('barcode', 'Please enter your barcode');
  required('batchcode', 'Please enter your batch code');

  if (command.barcode && EMAIL_REGEX.test(command.barcode)) {
    messages.addError('barcode', 'Please enter a valid barcode');
  }

  if (command.batchcode && EMAIL_REGEX.test(command.batchcode)) {
    messages.addError('batchcode', 'Please enter a valid batch code');
  }

  return messages;
  
  function required(prop, error) {
    if (!command[prop]) {
      messages.addError(prop, error);
    }
  }
}

export default {
  validate
};
