import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import timer from 'utils/timer';
import prizeClaimService from 'services/prizeClaimService';
import otpService from 'services/otpService';
import useSettingsContext from 'contexts/useSettingsContext';

import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import Link from 'components/Link/Link';

import OTPConfirmationForm from 'containers/Forms/OTPConfirmationForm';
import Claim from 'containers/Forms/Claim';
import AlertIcon from 'assets/images/alert.png';
import './OTP.scss';



export default (props) => {
    let settings = useSettingsContext();

    let { token } = props;
    let [claim, setClaim] = useState();
    let [country, setCountry] = useState(null);
    const MAX_ATTEMPTS = settings.otpMaxClaimTries;
    let [otpStatus, setOtpStatus] = useState("");
    let [modalStatus, setModalStatus] = useState("");

    let [flowId, setFlowId] = useState("");
    let [claimOtp, setClaimOtp] = useState("");
    let [entryFlowToken, setEntryFlowToken] = useState("");
    let [disableOTPFields, setDisableOTPFields] = useState(true);
    let [clearData, setClearData] = useState(false);
    let [competitionEntryId, setCompetitionEntryId] = useState(""); 
    let [otpValidationError, setOtpValidationError] = useState(""); 
    let [currNumAttp, setCurrNumAttp] = useState(0);
        


    useEffect(() => {
        async function fetchData() {
          await prizeClaimService.getPrizeClaim(token)
            .then((data) => {                
                if(data) {                    
                    setClaim(data);
                    setCountry(data.country);
                    setFlowId(data.flowId);
                    setCompetitionEntryId(data.competitionEntryId);
        
                    if(data.canClaim) {
                        setOtpStatus("confirm-otp");
                        setDisableOTPFields(false);
                    } else {
                        setOtpStatus("invalid");
                        setOtpValidationError(data.error.Message);
                    }
                    
                } 
            })
            .catch(err => console.log(err)) ;
        }
     
        if (token && !claim) {
          fetchData();
        }
    }, [claim, token]);
    
    if (token && !claim) {
        return (
            <div>Loading...</div>
        )
    }

    async function onConfirmOTPSubmit(data) {
        setModalStatus("Uploading");
        setDisableOTPFields(true);
        setClearData(false);
        await timer.delay(1000);
        
        await otpService.validateOTP(data).then((currOtpData) => {                        

            if (currOtpData.isValid && currOtpData.numTries < MAX_ATTEMPTS ) {
                setEntryFlowToken(currOtpData.entryFlowToken);
                setClaimOtp(data.otp);
                //setModalStatus('Valid');
                clearModalStatus("Valid"); 
                timer.delay(1500);
                clearModalStatus("");
                setOtpStatus("claim-form");

            }
            else if(!currOtpData.isValid && currOtpData.error == null) {                
                setOtpValidationError("Something went wrong.");
               // setModalStatus("");
                clearModalStatus(""); 
                setOtpStatus("invalid");
            }  
            else if (!currOtpData.isValid && currOtpData.numTries < MAX_ATTEMPTS) {                
                if (currOtpData.error && currOtpData.error.errorCode && currOtpData.error.errorCode === "cs-prize-claim-otp-wrong-code") 
                {                    
                    setCurrNumAttp(currOtpData.numTries);
                    //setModalStatus('NotFound');
                    clearModalStatus("NotFound");       
                } else {
                    setOtpValidationError(currOtpData.error.message);
                    clearModalStatus(""); 
                    setOtpStatus("invalid");
                }                             
            } else if (!currOtpData.isValid && currOtpData.numTries >= MAX_ATTEMPTS) {
                clearModalStatus(""); 
                setOtpStatus('TooManyAttempts');
            } 
        })
      
        .catch((err) => {
            //setModalStatus("");     
            clearModalStatus("");       
            setOtpStatus("invalid");
        });

    }


    function onDismissModal () {
        setDisableOTPFields(false);
        setClearData(true);

        timer.delay(500);
        clearModalStatus("");
    }

    function clearModalStatus (status) {
        document.body.classList.remove("overflow-hidden-mediumdown"); 
        setModalStatus(status);  
    }

    function renderStatus(otpStatus) {

        switch (otpStatus) {
        
        case "confirm-otp": 
        return (<>
            <Helmet>
            <meta charSet="utf-8" />
            <title>Claim your prize | Cadbury FC - Home & Away Wins</title>
            </Helmet>
            <section className="layout layout-general layout-wrapper">
                <div className="layout-container layout-container--slim layout-container--center otp-validation">
                    
                    <h1 className="type-h1">Claim your prize</h1> 
                    <div className='form__intro' >
                        <p>Congratulations on winning one of our epic Home & Away Wins prizes. Before we can share your prize details, we just need to check it’s really you!</p>
                        <p>Please enter the one-time passcode sent to the email address you used to enter the Promotion.</p>
                        <p>Don’t forget to check your junk mail!</p>
                    </div>
                    
                    <OTPConfirmationForm
                        onSubmit={onConfirmOTPSubmit} 
                        flowId={flowId}
                        actionId="claim"
                        competitionEntryId={competitionEntryId}
                        disableOTPFields={disableOTPFields}
                        clearData={clearData}
                        country={country}                        
                        
                    />                    
                    <div className='form__privacy text-center'>
                        <p>
                        If you haven’t received an email please contact<br/>
                        <Link href="mailto:cadburyhomeaway@promowinners.com" target="_blank" title="cadburyhomeaway@promowinners.com" >CadburyHomeAway@promowinners.com</Link>
                        </p>
                    </div>
                </div>
            </section>

            {
                modalStatus === "Uploading" &&
                <Modal loading />
            }
            {
                modalStatus === "Valid" &&
                <Modal success />
            }
            {
                modalStatus === "NotFound" &&
                <Modal 
                    panel 
                    error
                    icon={AlertIcon} 
                    alt="Warning sign"
                    onConfirm={onDismissModal}
                    title="Not found">                      
                    <p>
                    Hmm, we don't recognise that code! Please try again. 
                    </p>
                    {
                        (currNumAttp === 3 || currNumAttp === 4) &&
                        <p>
                            Be aware that you only have {(5 - currNumAttp)} remaining {(5 - currNumAttp) === 1 ? "attempt" : "attempts"}.
                        </p>
                    }
                    
                    <Button title="Try again" onClick={onDismissModal} />
                    <Link title="Contact Promoter" href="mailto:cadburyhomeaway@promowinners.com" target="_blank">Contact Promoter</Link>
                </Modal>
            }   
                
            
        </>)
        case 'claim-form': return (
            <Claim 
                otpAvilable={true}
                token={token}
                otp={claimOtp}
                flowId={flowId}
                entryFlowToken={entryFlowToken}
            />
        )
        case 'TooManyAttempts': return (
            <>
            <Helmet>
            <meta charSet="utf-8" />
            <title>Error - Claim your prize | Cadbury FC - Home & Away Wins</title>
            </Helmet>
            <section className="layout layout-general layout-wrapper">
                <div className="layout-container layout-container--slim layout-container--center otp-validation">
                    
                    <h1 className="type-h1">Too many attempts</h1> 
                    <div className="form__intro">
                        <p >
                            Identity validation locked due to suspicious activity.
                        </p>

                        <p>
                        <Link title="Contact Promoter" href="mailto:cadburyhomeaway@promowinners.com" target="_blank">Contact Promoter</Link>
                        </p>
                    </div>
                    <Button title="Back to homepage" href="/" />
                </div>
            </section>
            </>
        )
       
        case 'invalid': return (
            <>
            <Helmet>
            <meta charSet="utf-8" />
            <title>Sorry - Claim your prize | Cadbury FC - Home & Away Wins</title>
            </Helmet>
            <section className="layout layout-general layout-wrapper">
                <div className="layout-container layout-container--slim layout-container--center otp-validation">
                    
                    <h1 className="type-h1">Sorry!</h1> 
                    <div className="form__intro">
                        <p>
                            { claim && claim.error!= null && claim.error.message ? claim.error.message : "Sorry, something went wrong"}                             
                        </p>

                        {
                            otpValidationError && 
                            <p>{otpValidationError}</p>
                        }
                    </div>
                    <div className='otp-validation__ctas'>
                        <Button title="Back to homepage" href="/" />
                        <Link title="Contact Promoter" href="mailto:cadburyhomeaway@promowinners.com" target="_blank">Contact Promoter</Link>
                    </div>
                </div>
            </section>
            </>
            
        )
        
        }
        
    }

    return <>
        {
            renderStatus(otpStatus)
        }
        
    </>;
}