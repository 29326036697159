import React, { useState } from 'react';
import { useEffect } from 'react';
import classList from 'react-classlist-helper';
import useSettingsContext from 'contexts/useSettingsContext';
import './ActionBar.scss';

export default (props) => {
  let settings = useSettingsContext();
  const [scrollTop, setScrollTop] = useState();
  const [scrolling, setScrolling] = useState();
  const [bottom, setBottom] = useState();

  const classMap = {
    "actionbar": true,
    "actionbar--show": props.show,
  };
/*
  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);

      let max = settings.contentHeight - settings.windowHeight;
      setBottom(scrollTop > max ? `${scrollTop - max}px` : '0px');
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);*/

  return (
    <div className={classList(classMap)} style={ { bottom } }>
      { props.children }
    </div>
  )
}